import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getDate } from 'src/app/helper/date_format';
import { downloadAnyFile } from 'src/app/helper/downloader';
import { addExamObtDataList, addJambDatils, courseList, jambsubjectsList } from 'src/app/model/student';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-studentexaminationdetails',
  templateUrl: './studentexaminationdetails.component.html',
  styleUrls: ['./studentexaminationdetails.component.css']
})
export class StudentexaminationdetailsComponent implements OnInit {
  studentId: number
  pageLoading: boolean = false
  errOccured: boolean = false

  selectedStudent: any
  examinationRequestList1: any
  examinationRequestList2: any
  // courseLists : any[] 
  examObtDataListPayload = new addExamObtDataList()
  jambRequestList: any
  index: number = 1
  totalScore: number = 0

  jambsubjectslist = new jambsubjectsList()
  jambsubjectsLists: jambsubjectsList[] = []
  courselists: courseList[] = []
  courselists2: courseList[] = []

  hndRequestList: any

  constructor(private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })

  }

  ngOnInit(): void {
    this.getStudentDetails()
  }


  getDate(value) {
    return getDate(value)
  }


  download() {
    let StudentId = this.service.helperService.getUserId();
    this.service.fileService.downloadStudentForm(StudentId)
      .subscribe(rs => {
        this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['data'])
        downloadAnyFile(rs, 'docx')

      }, error => {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, error)
        console.log(error)
      })
  }

  getStudentDetails() {
    this.errOccured = false
    this.pageLoading = true

    this.service.studentService.getStudentDetails(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          console.log(res['data'])
          this.examinationRequestList1 = res['data'].examinationRequestList[0]
          this.courselists = this.examinationRequestList1?.courseList
          this.examinationRequestList2 = res['data'].examinationRequestList[1]
          this.courselists2 = this.examinationRequestList2?.courseList
          this.jambRequestList = res['data'].jambRequestList
          this.jambsubjectsLists = this.jambRequestList?.subjectsList
          if (this.jambsubjectsLists) {
            this.jambsubjectsLists.forEach(en => {
              this.totalScore += en.scores
            })
          }
          this.hndRequestList = res['data'].hndRequestList[0]
          this.pageLoading = false;

        } else {
          this.pageLoading = false;
          console.log(res['data'])
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
        }
      }, err => {
        this.pageLoading = false;
        this.errOccured = true
        console.log(err, "Err")
        this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, err)
      })
  }

}
