import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../api/app.service';

@Component({
  selector: 'app-studentview',
  templateUrl: './studentview.component.html',
  styleUrls: ['./studentview.component.css']
})
export class StudentviewComponent implements OnInit {

  constructor(private service: AppService, private _route: Router) { }

  ngOnInit(): void {
  }

  goback() {
    this._route.navigateByUrl("/home/application")
  }
}

