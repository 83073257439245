import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseService } from 'src/app/api/Base.service';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-form-head',
  templateUrl: './form-head.component.html',
  styleUrls: ['./form-head.component.css']
})
export class FormHeadComponent implements OnInit {

  student_id: number;
  fullname: string;
  imagePreview: string;
  formNumber: string
  session: string

  constructor(private service: AppService, private router: ActivatedRoute, private base: BaseService) {
    this.router.params.subscribe(param => {
      this.student_id = param.studentid
    })
  }

  ngOnInit(): void {
    this.fullname = this.service.helperService.getUserName()
    this.imagePreview = "assets/layout/images/default.png"
    this.getUsersImage();
    this.get_student_details()
  }

  getUsersImage() {
    let category = "student"
    let baseUrl = this.base._baseUrl
    this.imagePreview = baseUrl + `/preview/photo?category=${category}&id=${this.student_id}`
    // console.log(this.imagePreview)
  }

  get_student_details() {
    this.service.studentService.getStudentDetails(this.student_id)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.formNumber = res['data'].formnumber
          this.session = res['data'].sessions
        }
        else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
        }
      }, err => {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

}
