import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-viewparentdetails',
  templateUrl: './viewparentdetails.component.html',
  styleUrls: ['./viewparentdetails.component.css']
})
export class ViewparentdetailsComponent implements OnInit {
  pageloading: boolean = false
  errOccured: boolean = false
  studentId: number
  schoolfeeRequestList: any
  schorlarship: any
  schorshipreason: any
  institutionsList: any[] = []

  constructor(private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })
  }

  ngOnInit(): void {
    this.getStudentDetails()
  }

  getStudentDetails() {
    this.errOccured = false
    this.pageloading = true
    this.service.studentService.getStudentDetails(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.pageloading = false;
          this.schoolfeeRequestList = res['data'].schoolfeeRequestList[0]
          this.institutionsList = res['data'].institutionsList
        } else {
          this.pageloading = false;
          console.log(res['data'])
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
        }
      }, err => {
        this.errOccured = true
        this.pageloading = false;
        console.log(err, "Err")
        this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, err)

      })
  }

}
