import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BaseService { 
  // public _baseUrl: string = 'http://safpoly.org:8080'
  public _baseUrl: string = 'https://api.safpoly.org'


  constructor() { }
  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error || "Server Error")
  }
}