import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { resetPassword } from '../model/entrance';
import { approveReject, Staff, updateDept } from '../model/staff';
import { addCourses, addSession, getAvailableDepartment } from '../model/student';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient, private base: BaseService) { }

  private baseUrl = this.base._baseUrl + '/admin'

  addStaff(payload: Staff): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/create-staff`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addCourses(payload: addCourses): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add-courses`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addUpdateSession(payload: addSession): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add-update-session`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addDepartment(payload: getAvailableDepartment): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add-update/dept`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  approveRejectStudent(payload: approveReject): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/approve-reject-student`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  updateDept(payload: updateDept): Observable<any>{
    return this.http.post<any>(this.baseUrl +
      `/update-dept`, payload)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }
  // **********************   GET START HERE ***********************
  getAllCourse(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-all-courses`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getAllStudent(session : string) {
    return this.http.get<any>(this.baseUrl +
      `/get-all-students?session=${session}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getAvailableDepartment(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-available-course`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getDeptById(id: number) {
    return this.http.get<any>(this.baseUrl +
      `/get-dept-byid?id=${id}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getSession(): Observable<any>{
    return this.http.get<any>(this.baseUrl +
      `/get-sessions`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  getByStatus(session: string, status: string){
    return this.http.get<any>(this.baseUrl +
      `/get-status?session=${session}&status=${status}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getAvailablePin(): Observable<any>{
    return this.http.get<any>(this.baseUrl +
      `/getAvailable/pin`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  getAvailableCourse(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-available-course`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCourseByProgLevelSemester(level:string, programme: string, semester: string){
    return this.http.get<any>(this.baseUrl +
      `/getcourses-by-programsemester-level?level=${level}&programme=${programme}&semester=${semester}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getEnterpreneushipByCourseLevel(course:string, level: string,semester: string, session: string){
    return this.http.get<any>(this.baseUrl +
    
      `/get-enterpreneuship-by-dept?admissionyear=${session}&course=${course}&level=${level}&semester=${semester}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getAllPin(): Observable<any>{
    return this.http.get<any>(this.baseUrl +
      `/getpin`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }
  
  getUsedPin(): Observable<any>{
    return this.http.get<any>(this.baseUrl +
      `/getused/pin`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  listStaff(): Observable<any>{
    return this.http.get<any>(this.baseUrl +
      `/list-staff`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  entranceLogin(username: string, password: any): Observable<any>{
    return this.http.get<any>(this.baseUrl + 
      `/login?password=${password}&username=${username}`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  passwordReset(payload : resetPassword): Observable<any>{
    return this.http.post<any>(this.baseUrl + 
      `/password-reset`, payload)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }

  
  // *********************  DELETE START HERE ********************
  deleteDepartment(id: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete/course?id=${id}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
