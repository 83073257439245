import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AppService } from 'src/app/api/app.service';
import { downloadAnyFile } from 'src/app/helper/downloader';

@Component({
  selector: 'app-payment-history',
  templateUrl: './payment-history.component.html',
  styleUrls: ['./payment-history.component.css']
})
export class PaymentHistoryComponent implements OnInit {

  pageloading: boolean = false
  errOccured: boolean = false
  payment_history = [];
  student_id: number
  rowGroupMetadata: any;
  activityValues: number[] = [0, 100];
  @ViewChild('dt') table: Table;
  show_addPayment: boolean;  

  // constructor(private service: AppService, private route: Router) { }
  constructor(private fb: FormBuilder, private service: AppService, private router: ActivatedRoute,) {
    this.router.params.subscribe(param => {
      this.student_id = +param.studentid
    })
  }
  ngOnInit(): void {
    this.show_addPayment = false
    this.getStudentPayHistroy();
  }

  addPayemnt() {
    this.show_addPayment = true
  }
  closeModal(info=''){
    this.show_addPayment = false
    if(info == "Saved"){
      // this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, "Successful")
      this.getStudentPayHistroy()
    }
  }
  
  getStudentPayHistroy() {
    this.pageloading = true
    this.errOccured = false
    this.service.studentService.getStudentPaymentRecord(this.student_id)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.payment_history = res['data']
          this.pageloading = false
          
        }
        else {
          this.pageloading = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['data'])
        }
      }, err => {
        this.pageloading = false
        this.errOccured = true
        console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
      })
  }

  downloadExaminationClearance(row){    
      this.service.showMessage(this.service.MSG_INFO, this.service.INFO_TITLE, this.service.PROCESS_DOWNLOAD)      
      this.service.fileService.downloadStudentsExaminationClearance(row?.level, row?.semester, row?.session, this.student_id)
        .subscribe(rs => {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, "Clearance Form Downloaded ")
          downloadAnyFile(rs, 'pdf')
        }, error => {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        })    
  }
}
