import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/api/app.service';
import { addOlevelSubject } from 'src/app/model/student';

@Component({
  selector: 'app-olevel',
  templateUrl: './olevel.component.html',
  styleUrls: ['./olevel.component.css']
})
export class OlevelComponent implements OnInit {
  olevelForm: FormGroup;
  loading: boolean;
  olevelloading: boolean;
  subjects: any[];
  errSubject: boolean;
  displayBasic: boolean = false;
  personalErr: string;

  constructor(private service: AppService, private fb: FormBuilder, private _route: Router) { }


  ngOnInit(): void {
    this.olevelForm = this.fb.group({
      'subject': ['', Validators.required],
    })

    this.getAllSubejct()
  }

  showBasicDialog() {
    this.displayBasic = true;
    this.olevelForm.reset()
  }

  closeModal() {
    this.displayBasic = false;
  }
  goback() {
    this._route.navigateByUrl("/home/settings")
  }

  addOlevelSubject() {
    let form = this.olevelForm

    if (form.get('subject').value != null) {
      this.personalErr = ''
      let addCourse = new addOlevelSubject()
      this.loading = true

      addCourse.subject = form.get('subject').value

      this.service.studentService.addOlevelSubject(addCourse)
        .subscribe(rs => {
          if (rs['message'] == this.service.SUCCESS) {
            this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['data'])
            this.loading = false
            this.getAllSubejct()
            this.closeModal()
          } else {
            this.loading = false
            this.personalErr = rs['data']
            this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['data'])
            console.log("not saved", rs['data'])

          }
        }, error => {
          this.loading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, error)
          console.log(error)
        })
    }
    else{
      this.personalErr = "Enter subject"
    }
  }
  editSubject(subject) {
    this.showBasicDialog()

  }
  getAllSubejct() {
    this.olevelloading = true
    this.errSubject = false
    this.subjects = []
    this.service.studentService.getOlevelSubject()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          let sub = res['data']
          sub.forEach(element => {
            if(element.subject != null){
              this.subjects.push(element)
            }
          });
          this.olevelloading = false
        }
        else {
          this.olevelloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['message'])
        }
      }, err => {
        this.olevelloading = false
        this.errSubject = true
        console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

}
