import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-empty-state',
  templateUrl: './empty-state.component.html',
  styleUrls: ['./empty-state.component.css']
})
export class EmptyStateComponent implements OnInit {

  @Output() private retry:EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  tryOperation(){
    this.retry.emit()
  }

}
