import { AppService } from 'src/app/api/app.service';
import { addStudent, file, parentGuardian, parentGuardianDataList } from 'src/app/model/student';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, OnInit } from '@angular/core';
import {  fileUploaded } from 'src/app/model/file';
import { getDate, getDay,  } from 'src/app/helper/date_format';
import { Util } from 'src/app/helper/utilities';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.css']
})
export class PersonalDetailsComponent implements OnInit {
  File: File;
  fileName: string
  firstLoading: boolean = false

  countries: any[];
  personalDetailsForm: FormGroup
  personalGuardianForm: FormGroup

  studentId: number
  studentEmail: string

  selectedCity1: any;
  selectedCity2: any;
  cities: any;
  nation: any
  religion: any[]
  maritalStatus: any
  proposedcourses: any
  show: boolean = true;
  showing: any = "Yes";
  //  showingNo : boolean = false
  isPhysicalReason: string
  // payload = new fileUploaded()
  files = new file()
  // payloadList: any 
  modalClosed: boolean = false
  loading: boolean = false
  loadingPersonalG: boolean = false
  loadingRemove: boolean = false
  loadingUpload: boolean = false
  pageLoading: boolean = false
  ParentGuardianID: number = 0
  personalDetailsID: number = 0

  selectedCountry: string;
  // selectedCountry: string;
  cr: any
  days = []
  courses: any[];
  courseloading: boolean;
  ndLoading: boolean;
  fileUploadedList: fileUploaded[] = []
  genderOption = [{ name: "Male" }, { name: "Female" }]

  constructor(private fb: FormBuilder, private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })

  }
  isCompleted: boolean = false
  ngOnInit() {
    this.personalDetailsForm = this.fb.group({
      'fullname': ['', Validators.required],
      'contactAddress': ['', Validators.required],
      'parmanentAddress': ['', Validators.required],
      'phoneNo': ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{11}")])],
      'email': [this.studentEmail ],
      'day': [''],
      'dateOfBrt': [new Date, Validators.required],
      'nationality': ['', Validators.required],
      'religion': ['', Validators.required],
      'maritalStatus': ['', Validators.required],
      'lga': ['', Validators.required],
      'state': ['', Validators.required],
      'proposedcourse': ['', Validators.required],
      'gender': ['', Validators.required],
      // 'isPhysical' :[] 
    })
    // this.personalDetailsForm.get('isPhysical').setValue(1)
    this.personalGuardianForm = this.fb.group({
      'pcontactAddress': ['', Validators.required],
      'pFullname': ['', Validators.required],
      'pMothermobile': ['', Validators.pattern("[0-9]{11}")],
      'pFathermobile': ['', Validators.pattern("[0-9]{11}")],
      'pEmail': ['', Validators.email],
      'pRelationship': [''],
    })

    this.nation = [
      { name: 'Nigeria', code: 'NIG' },
    ];

    this.cities = Util.state
    this.religion = [{ name: "Islam" }, { name: "Christian" }, { name: "Traditional" }, { name: "Other" }]
    this.maritalStatus = [{ name: "Single" }, { name: "Married" }, { name: "Divorce" }, { name: "Other" }]
    for (let i = 1; i < 32; i++) {
      if (i < 10) {
        this.days.push({ name: '0' + i.toString() })
      }
      else {
        this.days.push({ name: i.toString() })
      }
    }
    this.getAttachFile()
    this.getPersonalDetails()
    this.getAllCourseAvailable()
  }

  getDay(date) {
    return getDay(date)
  }

  getAllCourseAvailable() {
    this.courseloading = true
    // this.errCourse = false
    this.proposedcourses = []
    this.service.adminService.getAvailableCourse()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.proposedcourses = res['data']
          this.courseloading = false
        }
        else {
          this.courseloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
        }
      }, err => {
        this.courseloading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  openDialog() {
    this.modalClosed = !this.modalClosed
  }

  isPhysicalChanllenge() {
    if (this.showing == "Yes" || this.showing == true) {
      this.show = true
    }
    else {
      this.show = false
    }
  }
  addPersonalGuardian() {
    // this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_SAVING, "")

    this.loadingPersonalG = true
    let addparentGuardian = new parentGuardian()
    let parentGuardianDataLst = new parentGuardianDataList()
    let form = this.personalGuardianForm
    parentGuardianDataLst.contactAddress = form.get('pcontactAddress').value
    parentGuardianDataLst.mothermobile = form.get('pMothermobile').value
    parentGuardianDataLst.fullname = form.get('pFullname').value
    parentGuardianDataLst.fathermobile = form.get('pFathermobile').value
    parentGuardianDataLst.emailAddress = form.get('pEmail').value
    parentGuardianDataLst.relationship = form.get('pRelationship').value
    parentGuardianDataLst.id = this.ParentGuardianID

    addparentGuardian.dataList = [parentGuardianDataLst]
    addparentGuardian.student = this.studentId

    this.service.studentService.addParentGuardian(addparentGuardian)
      .subscribe(rs => {

        if (rs['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['message'])
          this.loadingPersonalG = false
          this.loading = false
          console.log(addparentGuardian)
        } else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, rs['message'])
          this.loadingPersonalG = false
          this.loading = false
        }
      }, error => {
        this.loadingPersonalG = false
        this.loading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  addPersonalDetail() {
    this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_SAVING, "")
    let addstudent = new addStudent()
    // this.loading = true

    let form = this.personalDetailsForm
    addstudent.fullname = form.get('fullname').value
    addstudent.contactaddress = form.get('contactAddress').value
    addstudent.permanentaddress = form.get('parmanentAddress').value
    addstudent.phone = form.get('phoneNo').value
    addstudent.email = this.studentEmail    
    addstudent.dob = getDate(form.get('dateOfBrt').value)
    addstudent.state = form.get('state').value
    addstudent.nationality = form.get('nationality').value
    addstudent.religion = form.get('religion').value
    addstudent.proposedcourse = form.get('proposedcourse').value
    addstudent.gender = form.get('gender').value
    addstudent.maritalstatus = form.get('maritalStatus').value
    addstudent.lga = form.get('lga').value
    addstudent.id = this.personalDetailsID
    addstudent.healthchallenge = this.showing
    if (this.showing == "No")
      addstudent.healthreason = ""
    else
      addstudent.healthreason = this.isPhysicalReason
    if (this.files?.id != null)
      addstudent.courtavidavid = "Yes"
    else
      addstudent.courtavidavid = "No"
    addstudent.files = this.files
    // this.File = this.files
    addstudent.id = this.studentId
    console.log(addstudent)
    this.service.studentService.addStudent(addstudent)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.personalDetailsID = rs['data']
          // this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['message'])
          this.addPersonalGuardian()
        } else {
          this.loading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, rs['message'])
        }
        console.log(rs)
      }, error => {
        this.loading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })

  }

  closeModal() {
    this.modalClosed = false
  }

  selectFile(fileInput: any) {

    this.File = <File>fileInput.target.files[0];
    this.fileName = fileInput.target.files[0].name
    //this.uploadDoc();
  }

  remove(filename) {
    this.loadingRemove = true
    this.service.fileService.deleteFile(filename).subscribe(
      (res) => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.loadingRemove = false
          this.files = new file()
        }
        else {
          this.loadingRemove = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
        }
      }, err => {
        this.loadingRemove = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  uploadDoc() {
    this.loadingUpload = true
    let id = this.service.helperService.getUserId()
    let category = "student"

    let formData = new FormData();
    formData.append('file', this.File, this.File.name);

    this.service.fileService.fileUpload(id, category, "affidavit", formData).subscribe(
      (res) => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.loadingUpload = false
          this.files = new file()
          this.files.description = res['data'].category;
          this.files.filename = res['data'].filename;
          this.files.id = res['data'].id;
          this.closeModal()
        }
        else {
          this.loadingUpload = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
          this.closeModal()
        }
      }, err => {
        this.loadingUpload = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        this.closeModal()

      })
  }

  getdate(value) {
    return getDate(value)
  }

  getPersonalDetails() {
    this.pageLoading = true
    this.firstLoading = false
    this.service.studentService.getStudentDetails(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          let addstudent = new addStudent()
          addstudent = res['data']
          // this.cr = JSON.parse(addstudent?.proposedcourse)
          let form = this.personalDetailsForm
          form.get('fullname').setValue(addstudent?.fullname)
          form.get('contactAddress').setValue(addstudent?.contactaddress)
          form.get('parmanentAddress').setValue(addstudent?.permanentaddress)
          form.get('phoneNo').setValue(addstudent?.phone)
          this.studentEmail = addstudent?.email
          form.get('email').setValue(addstudent?.email)
          // let dob = getDate(addstudent?.dob)
          form.get('dateOfBrt').setValue(getDate(addstudent?.dob))
          form.get('state').setValue(addstudent?.state)
          form.get('nationality').setValue(addstudent?.nationality)
          form.get('religion').setValue(addstudent?.religion)
          form.get('proposedcourse').setValue(addstudent?.proposedcourse)
          form.get('gender').setValue(addstudent?.gender)
          // form.get('proposedcourse').setValue(JSON.parse(addstudent?.proposedcourse))
          form.get('maritalStatus').setValue(addstudent?.maritalstatus)
          form.get('lga').setValue(addstudent?.lga)
          this.personalDetailsID = addstudent?.id
          this.isPhysicalReason = addstudent?.healthreason
          this.showing = addstudent?.healthchallenge
          this.files = addstudent?.files
          // this.pageLoading = true
          this.isPhysicalChanllenge()
          this.getParentalGuardian()
        }
        else {
          this.pageLoading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
        }
      }, err => {
        this.pageLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  getParentalGuardian() {
    this.pageLoading = true
    this.service.studentService.getParentGuardian(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          // this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          let parentGuardianDataLst = res['data'][0]
          if (parentGuardianDataLst) {
            let form = this.personalGuardianForm
            form.get('pcontactAddress').setValue(parentGuardianDataLst?.contactAddress)
            form.get('pMothermobile').setValue(parentGuardianDataLst?.mothermobile)
            form.get('pFullname').setValue(parentGuardianDataLst?.fullname)
            form.get('pFathermobile').setValue(parentGuardianDataLst?.fathermobile)
            form.get('pEmail').setValue(parentGuardianDataLst?.emailAddress)
            form.get('pRelationship').setValue(parentGuardianDataLst?.relationship)
            this.ParentGuardianID = parentGuardianDataLst?.id
          }
          this.pageLoading = false
        }
        else {
          this.pageLoading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['data'])
        }
      }, err => {
        this.pageLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  getAttachFile() {
    // this.pageLoading = true 
    this.ndLoading = true
    this.fileUploadedList = []
    this.service.fileService.getAttachFile(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          if (res['data'].length > 0 && res['data'][0].id != null) {
            this.fileUploadedList = res['data']
            this.files = new file()
            this.fileUploadedList.filter(x => x.type == "affidavit" && x.category == "student").forEach(el => {
              this.files.id = el.id
              this.files.filename = el.filename
              this.files.description = el.category
            })
          }
          this.ndLoading = false
        }
        else {
          // this.service.showMessage(this.service.MSG_INFO, this.service.FAILED, res['data'])
          this.ndLoading = false
        }
      }, err => {
        this.ndLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }



}

// let month_year = ''
    // console.log(Type(form.get('dateOfBrt').value))
    // if (Type(form.get('dateOfBrt').value) == String) {
    //   // month_year = getYearMonth(form.get('dateOfBrt').value)
    //   console.log(form.get('dateOfBrt').value)
    //    let data = form.get('dateOfBrt').value.split('/')
    //    console.log(data)
    //    month_year = (data[1] +'-'+data[0]).toString()
    // }
    // else{
    //   console.log(form.get('dateOfBrt').value)
    //   let data = getDate(form.get('dateOfBrt').value);
    //   console.log(data)
    //   month_year = (data[1] +'-'+data[0]).toString()
    // }
    // let day = form.get('day').value
    // addstudent.dob = month_year + '-' + day

    

          // if (Type(addstudent.dob) == Number) {
          //   let dob = new Date(getDate(addstudent?.dob))
          //   form.get('dateOfBrt').setValue(dob)
          //   form.get('day').setValue(getDay(dob))
          //   console.log(addstudent?.dob)
          // }
          // else {
          //   let dob = addstudent?.dob
          //   let date = []
          //   if (dob.includes('-')) {
          //     date = dob.split('-')     // yyyy-mm-dd
          //     form.get('dateOfBrt').setValue(date[1] + '/' + date[0])   // mm by year
          //     form.get('day').setValue(+date[2])              // day              
          //   }
          //   else {
          //     date = dob.split('/')     //  dd/mm/yyyy
          //     let year_month = date[1] + '/' + date[2]
          //     // let year_month = dob
          //     form.get('dateOfBrt').setValue(year_month)
          //     form.get('day').setValue(date[0])              
          //   }
          // }