import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/api/app.service';
import { Router } from '@angular/router';
import { addCourses } from 'src/app/model/student';

@Component({
  selector: 'app-courseform',
  templateUrl: './courseform.component.html',
  styleUrls: ['./courseform.component.css']
})
export class CourseformComponent implements OnInit {
  // [x: string]: any;

  personalDetailsForm: FormGroup
  courseForm: FormGroup
  modalClosed: boolean = false;
  displayBasic: boolean = false;
  loading: boolean = false
  courseID: number = 0
  errCourse: boolean;
  courseloading: boolean;
  courses: any[];  
  public show: boolean = false;
  loadingRemove: boolean;

  semester  = [{name:'First'},{ name: 'Second'}]
  programme = [{name:'ND'},{name: 'HND'}]
  level = [{name:'I'},{name: 'II'}]
  File: File;
  fileName: any;
  loadingUpload: boolean;
  
  constructor( private service: AppService, private fb: FormBuilder, private _route: Router) {}

  ngOnInit(): void {
    // this.productService.getProductsSmall().then(data => this.products = data);
    this.courseForm = this.fb.group({
      'coursecode': ['', Validators.required],
      'coursetitle': ['', Validators.required],
      'programme': ['', Validators.required],
      'semester': ['', Validators.required],
      'level': ['', Validators.required],
    })
    this.getAllCourse()
  }

  toggled() {
    this.show = !this.show;
  }

  goback() {
    this._route.navigateByUrl("/home/settings")
  }

  editCourseForm(courses) {
    let form = this.courseForm
    form.get('coursecode').setValue(courses.coursecode)
    form.get('coursetitle').setValue(courses.coursetitle)
    form.get('programme').setValue(courses.programme)
    form.get('semester').setValue(courses.semester)
    form.get('level').setValue(courses.level)
    this.courseID = courses.id
    this.displayBasic = true;
  }

  showBasicDialog() {
    this.displayBasic = true;
    this.courseForm.reset()
    this.courseID = 0
  }

  showUploadExcel(){
    this.File = null
    this.fileName = ""
    this.modalClosed= true
  }
  
  closeModal(){
    this.displayBasic = false;
    this.modalClosed= false
  }
  
  selectFile(fileInput: any) {
    this.File = <File>fileInput.target.files[0];
    this.fileName = fileInput.target.files[0].name
  }

  iBasicDialog() {
    this.displayBasic = false;
  }

  uploadDoc(sit) {
    this.service.showMessage(this.service.MSG_INFO, this.service.INFO_TITLE, this.service.PROCESS_UPLOAD)
    this.loadingUpload = true
    let id = this.service.helperService.getUserId()
    let formData = new FormData();
    formData.append('file', this.File, this.File.name);

    this.service.fileService.fileUploadCourse(formData).subscribe(
      (res) => {
        console.log(res)
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.loadingUpload = false
          this.closeModal()
          console.log(res['message'])
        }
        else {
          this.loadingUpload = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.ERROR_MESSAGE, res['message'])
          console.log(res['message'])
          // this.closeModal()
        }
      }, err => {
        console.log(err)
        this.loadingUpload = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
          console.log(err)
          // this.closeModal()
      })
  }

  addcourseForm() {
    let addCourse = new addCourses()
    this.loading = true

    let form = this.courseForm
    addCourse.coursecode = form.get('coursecode').value
    addCourse.coursetitle = form.get('coursetitle').value
    addCourse.programme = form.get('programme').value
    addCourse.semester = form.get('semester').value
    addCourse.level = form.get('level').value
    addCourse.id = this.courseID
    // console.log(addCourse)    

    this.service.adminService.addCourses(addCourse)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['data'])
          console.log("saved")
          this.loading = false
          this.getAllCourse()
          this.iBasicDialog()
        } else {
          this.loading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['data'])
          console.log("not saved", rs['data'])

        }
      }, error => {
        this.loading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, error)      
        console.log(error)
      })
  }
  
  getAllCourse() {
    this.courseloading = true
    this.errCourse = false
    this.courses = []
    this.service.adminService.getAllCourse()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.courses = res['data']
          // console.log(res)
          this.courseloading = false
        }
        else {
          this.courseloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['message'])
        }
      }, err => {
        this.courseloading = false
        this.errCourse = true
        console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

}
