import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-staylite',
  templateUrl: './staylite.component.html',
  styleUrls: ['./staylite.component.css']
})
export class StayliteComponent implements OnInit {


  errOccured: boolean = false
  students: [];
  // selectedStudent = []
  // statuses: any[];
  rowGroupMetadata: any;
  activityValues: number[] = [0, 100];
  @ViewChild('dt') table: Table;
  departments = [];
  departmentloading: boolean;


  programmes = [{ name: 'ND' }, { name: 'HND' }]
  

  level: string;
  session: string;
  programme : string
  department: string;
  pageloading: boolean;
  constructor(private service: AppService) { }

  ngOnInit(): void {
    // this.getAvailableDepartment()
  }

  getAvailableDepartment() {
    this.departmentloading = true
    // this.errDepartment = false
    this.departments = []
    this.pageloading = true
    this.errOccured = false
    this.service.adminService.getAvailableDepartment()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.departments = res['data']
          this.departmentloading = false
        }
        else {
          this.departmentloading = false
          this.pageloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])        
        }
      }, err => {
        this.departmentloading = false
        this.errOccured = true
        this.pageloading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  getStudentByDeptProgrammeSesion(){

  }
  getStudents(){

  }

}
