import { resetPassword } from './../model/entrance';
import { addExaminationObt, addJambDatils, addOlevelSubject, addPayemnt, addSchoolFee, addStudent, institutionAttd, ndResult, parentGuardian } from './../model/student';
import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../api/Base.service';
import { addCourseForm } from '../model/staff';

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http: HttpClient, private base: BaseService) { }

  private baseUrl = this.base._baseUrl + '/student'

  addStudent(payload: addStudent): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  addOlevelSubject(payload: addOlevelSubject): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/create-olevel`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addExaminationObt(payload: addExaminationObt): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/examination-obtained`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addinstitutionAttd(payload: institutionAttd): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/institution-attended`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  addJambDatils(payload: addJambDatils): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/jamb-data`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addNDResult(payload: ndResult): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/nd-result`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addParentGuardian(payload: parentGuardian): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/parent-guardian`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addSchoolFee(payload: addSchoolFee): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/add/school-fee-payment`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addCoursesForm(payload: addCourseForm): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/course-form`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  addMakePayment(payload: addPayemnt): Observable<any> {
    return this.http.post<any>(this.baseUrl +
      `/make-payment`, payload)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  getOlevelSubject(): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-olevel`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  checkStudentAdmissiomStatus(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/admitted-student?id=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCoursesByDeptId(deptId: number) {
    return this.http.get<any>(this.baseUrl +
      `/list-course-bydept?deptid=${deptId}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getExamObtained(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/examination-obtained?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getStudentDetails(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/students-details?id=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getStudentPaymentRecord(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-student-payment-record?id=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getPaidStudentByLevelSemesterSession(level: string, semester:string, session:string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-paid-student?level=${level}&semester=${semester}&session=${session}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getStudentByDeptProgrammeSesion(dept: string, programme: string, session: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-student-by-dept?dept=${dept}&programme=${programme}&session=${session}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getStudentByProgrammeSession(programme: string, session: string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `get-student-by-programme?programme=${programme}&session=${session}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  getCourseListsFormDetailsByDeptIdProgSemester(academicyear: string, deptid: number, level: string, programme: string, semester: string) {
    return this.http.get<any>(this.baseUrl +
      `/courseform?academicyear=${academicyear}&deptid=${deptid}&level=${level}&programme=${programme}&semester=${semester}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCourseListsFormDetails(academicyear: string, deptid: number) {
    return this.http.get<any>(this.baseUrl +
      `/courseform-sessions?academicyear=${academicyear}&deptid=${deptid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getCourseListsFormDetailsByID(id: number) {
    return this.http.get<any>(this.baseUrl +
      `/courseform-by-id?id=${id}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }


  getInstitutionAttend(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/institution-attended?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getJamb(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/jamb?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getNDResult(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/nd-result?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getParentGuardian(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/parent-quardian?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getSchoolFee(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/list/school-fee-payment?studentid=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getStudentByDeptId(id: number,admissionyear:string) {
    return this.http.get<any>(this.baseUrl +
      `/student-by-deptid?deptid=${id}&admissionyear=${admissionyear}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  // *********************  DELETE START HERE ********************
  deleteInstitutionAttended(id: number, studentId: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete/institution-attended?id=${id}&studentid=${studentId}`)
      .pipe(
        catchError(err => this.errorHandler(err))
      )
  }

  deleteExaminationObtain(id: number, studentId: number): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete/examination-obtained?id=${id}&studentid=${studentId}`)
      .pipe(
        catchError(err => this.errorHandler(err))
      )
  }
  deleteCourseForm(academicyear:any,dept: number, id: number, level: string, program: string, semester: string): Observable<any> {
    return this.http.delete<any>(this.baseUrl +
      `/delete-course-form?academicyear=${academicyear}&dept=${dept}&id=${id}&level=${level}&program=${program}&semester=${semester}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  getRegStage(studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-reg-stage?id=${studentid}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }


  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error")
  }





  // updatePassword(payload: resetPassword): Observable<any> {
  //   return this.http.post<any>(this.baseUrl +
  //     `/reset-password`, payload)
  //     .pipe(
  //       catchError(err => this.base.errorHandler(err))
  //     )
  // }

  // getRegStage(studentid: number): Observable<any> {
  //   return this.http.get<any>(this.baseUrl +
  //     `/get-reg-stage?id=${studentid}`)
  //     .pipe(
  //       catchError(err => this.base.errorHandler(err))
  //     )
  // }
}
