import { addExaminationObt, addExamObtDataList, courseList, file } from 'src/app/model/student';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { fileUploaded } from 'src/app/model/file';
import { AppService } from 'src/app/api/app.service';


@Component({
  selector: 'app-exam-taken',
  templateUrl: './exam-taken.component.html',
  styleUrls: ['./exam-taken.component.css']
})
export class ExamTakenComponent implements OnInit {

  examType: any
  grades: any
  sub: any
  items: MenuItem[];
  subject: string
  form2: FormGroup;
  form: FormGroup

  pageLoading: boolean = false

  studentId: number
  // ************** EXAM OBTAIN ******************
  examinationObt = new addExaminationObt()
  examinationObtDataList: addExaminationObt[] = []
  courselist = new courseList
  courselists: courseList[] = []
  examObtDataList: addExamObtDataList[] = []
  examObtDataListPayload = new addExamObtDataList()
  examID: number = 0
  courselist2 = new courseList
  courselists2: courseList[] = []
  // examObtDataList2: addExamObtDataList[] = []
  examObtDataListPayload2 = new addExamObtDataList()
  examID2: number = 0
  loadingExams: boolean = false

  sitOptions: any;
  sitNo: any[];
  seat: number = 1

  public show: boolean = false;
  public buttonName: any = 'Show';

  @Input() show_footer: boolean;
  @Output() public next: EventEmitter<any> = new EventEmitter<any>()
  @Output() public back: EventEmitter<any> = new EventEmitter<any>()
  isSitting2: boolean = false;
  isCompleted: boolean;
  loadingRemove: boolean;
  files: file;
  oLevelLoading: boolean;
  fileUploadedList: fileUploaded[] = []
  fileUploadedList2: fileUploaded[] = []
  loadingUpload: boolean;
  File: File
  modalClosed: boolean;
  fileName: any;
  files2: file;
  isOtherType:boolean = false
  loadingSubject: boolean;
  subjectMessage: string;

  constructor(private fb: FormBuilder, private service: AppService, private router: ActivatedRoute, private confirmationService: ConfirmationService) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })

    this.examType = [
      { name: 'WAEC SSCE' },
      { name: 'NECO' },
      { name: 'GCE O LEVEL' },
      { name: 'TECHNICAL COLLEGE' },
      { name: 'WAEC GCE' },
      { name: 'NECO GCE' },
      { name: 'OTHER' },
    ];
    
    this.sitNo = [{ no: 1 }, { no: 2 }];

    this.grades = [
      { name: 'A1' },
      { name: 'B2' },
      { name: 'B3' },
      { name: 'C4' },
      { name: 'C5' },
      { name: 'C6' },
      { name: 'D7' },
      { name: 'E8' },
      { name: 'F9' },
      { name: 'Awaiting' },
      { name: '***' }
    ];
    this.sitOptions = [{ label: 'First Exam', value: 0 }, { label: 'Second Exam', value: 1 }];
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      'subject': ['', Validators.required],
      'grade': ['', Validators.required],
      'examinationType': ['', Validators.required],
      'otherExaminationType': [''],
    })
    this.form2 = this.fb.group({
      'subject': ['', Validators.required],
      'grade': ['', Validators.required],
      'examinationType': ['', Validators.required],
      'otherExaminationType': [''],
    })
    this.getOlevelSubject()
    this.getForms()
  }

  is2Seat(sit) {
    if (sit == 1) {
      this.isSitting2 = true
    }
    else
      this.isSitting2 = false
  }
  checkSelection(){
    if (this.form.get('examinationType').value != "OTHER") {
      this.isOtherType == false
    }  
    else if(this.form.get('examinationType').value == "OTHER"){ 
      this.isOtherType == true
    }
    console.log("called")
  }
  nextPage() {
    this.next.emit()
  }

  prevPage() {
    this.back.emit()
  }

  toggle() {
    this.show = !this.show;
  }

  removed(index) {
    this.courselists.splice(index, 1)
  }
  removed2(index) {
    this.courselists2.splice(index, 1)
  }

  prepareCourseData1() {
    this.courselist = new courseList
    let subject = this.form.get('subject').value
    let grade = this.form.get('grade').value
    this.courselist = { subject, grade }
    this.courselists.push(this.courselist)
    this.form.get('grade').reset()
    this.form.get('subject').reset()
  }

  prepareCourseData2() {
    this.courselist2 = new courseList
    let subject = this.form2.get('subject').value
    let grade = this.form2.get('grade').value
    this.courselist2 = { subject, grade }
    this.courselists2.push(this.courselist2)
    this.form2.get('grade').reset()
    this.form2.get('subject').reset()
  }

  addExamObtDataList1() {
    this.examObtDataList = []
    this.examObtDataListPayload = new addExamObtDataList()
    this.examObtDataListPayload.courseList = this.courselists    
    this.examObtDataListPayload.examsType = this.form.get('examinationType').value    
    this.examObtDataListPayload.id = this.examID
    this.examObtDataList.push(this.examObtDataListPayload)

  }

  addExamObtDataList2() {
    this.examObtDataListPayload2 = new addExamObtDataList()
    this.examObtDataListPayload2.courseList = this.courselists2
    this.examObtDataListPayload.examsType = this.form2.get('examinationType').value
    this.examObtDataListPayload2.id = this.examID2
    this.examObtDataList.push(this.examObtDataListPayload2)

  }

  selectFile(fileInput: any) {
    this.File = <File>fileInput.target.files[0];
    this.fileName = fileInput.target.files[0].name
    //this.uploadDoc();
  }
  sits: string
  openDialog(sit) {
    this.sits = sit
    // this.files = new file()
    this.File = null
    this.fileName = ''
    this.modalClosed = !this.modalClosed
  }
  
  getOlevelSubject() {  
    this.loadingSubject = true
        this.subjectMessage = ''
        this.sub = []
    this.service.studentService.getOlevelSubject()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
        this.loadingSubject = false
        this.subjectMessage = ''
          this.sub = res['data']
        }
        else {
        this.loadingSubject = false
        this.subjectMessage = res['data'] //this.service.FAILED
        this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['data'])

      }
    }, err => {
        this.subjectMessage = this.service.ERROR_TITLE
        this.loadingSubject = false
      this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }


  getExamObtained() {
    let form1 = this.form
    let form2 = this.form2
    this.examObtDataListPayload = new addExamObtDataList()
    this.examObtDataListPayload2 = new addExamObtDataList()
    this.examObtDataList = []
    form1.reset()
    form2.reset()
    this.courselists = []
    this.courselists2 = []

    this.service.studentService.getExamObtained(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.seat = res['data'].length
          // this.is2Seat()
          this.examObtDataList = res['data']
          if (this.examObtDataList.length > 0) {
            // ********** FOR FIRST EXAM *************
            this.examObtDataListPayload = this.examObtDataList[0]
            this.courselists = this.examObtDataListPayload.courseList
            this.form.get('examinationType').setValue(this.examObtDataListPayload.examsType)
            this.examID = +this.examObtDataListPayload.id

            if (this.examObtDataList?.length == 2) {
              // ********** FOR SECOND EXAM *************
              this.examObtDataListPayload2 = this.examObtDataList[1]
              this.courselists2 = this.examObtDataListPayload2.courseList
              this.form2.get('examinationType').setValue(this.examObtDataListPayload2.examsType)
              this.examID2 = +this.examObtDataListPayload2.id
            }
          }
        }
        else {
          this.pageLoading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['data'])

        }
      }, err => {
        this.pageLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  addExamination() {
    this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_SAVING, "")
    this.loadingExams = true
    this.addExamObtDataList1()
    if (this.seat == 2) {
      this.addExamObtDataList2()
    }
    this.examinationObt = new addExaminationObt()

    this.examinationObt.dataList = this.examObtDataList
    this.examinationObt.studentid = + this.studentId
    this.service.studentService.addExaminationObt(this.examinationObt)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.loadingExams = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['data'])
          this.getExamObtained()
        } else {
          this.loadingExams = false

          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['message'])
        }
      }, error => {
        this.loadingExams = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  getForms() {
    this.getExamObtained()
    this.getAttachFile()
  }
  closeModal() {
    this.modalClosed = false
    this.File = null
    this.fileName = ''
    // this.files = new file()
  }

  uploadDoc(sit) {
    this.service.showMessage(this.service.MSG_INFO, this.service.INFO_TITLE, this.service.PROCESS_UPLOAD)
    this.loadingUpload = true
    let id = this.service.helperService.getUserId()
    let category = "student"

    //       this.closeModal()
    let formData = new FormData();
    formData.append('file', this.File, this.File.name);

    this.service.fileService.fileUpload(id, category, sit + "_olevel", formData).subscribe(
      (res) => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])

          this.loadingUpload = false
          if (sit == "First") {
            this.files = new file()
            this.files.description = res['data'].category;
            this.files.filename = res['data'].filename;
            this.files.id = res['data'].id;
            this.closeModal()
          }
          else if (sit == "Second") {
            this.files2 = new file()
            this.files2.description = res['data'].category;
            this.files2.filename = res['data'].filename;
            this.files2.id = res['data'].id;
            this.closeModal()
          }

        }
        else {
          this.loadingUpload = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])

          this.closeModal()

        }
      }, err => {
        this.loadingUpload = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)

        this.closeModal()

      })
  }

  remove(filename) {
    this.loadingRemove = true

    this.service.fileService.deleteFile(filename).subscribe(
      (res) => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])

          this.loadingRemove = false
          this.files = new file()
          this.getAttachFile()
        }
        else {
          this.loadingRemove = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])

        }
      }, err => {
        this.loadingRemove = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)

      })
  }
  confirmDelete() {
    this.confirmationService.confirm({
      message: 'Do you want to delete this Record',
      header: 'Delete Exam',
      icon: 'pi pi-info-circle',
      accept: () => {
        this.removeExam()
      },
      reject: () => {
        this.service.showMessage(this.service.MSG_INFO, this.service.CANCEL_TITLE, this.service.SUCCESS,)
      }
    });
  }

  removeExam() {
    this.service.showMessage(this.service.MSG_ERROR, this.service.PROCESS_DELETING, '')
    this.remove(this.files2.filename)
    this.service.studentService.deleteExaminationObtain(this.examID2, this.studentId).subscribe(
      (res) => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.getForms()
        }
        else {
          this.loadingRemove = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])

        }
      }, err => {
        this.loadingRemove = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)

      })
  }

  getAttachFile() {
    // this.pageLoading = true 
    this.oLevelLoading = true
    this.fileUploadedList = []
    this.fileUploadedList2 = []
    this.service.fileService.getAttachFile(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {

          if (res['data'].length > 0) {
            this.fileUploadedList = res['data']
            this.fileUploadedList2 = res['data']
            this.files = new file()
            this.fileUploadedList.filter(x => x.type == "First_olevel" && x.category == "student").forEach(el => {
              this.files.id = el.id
              this.files.filename = el.filename
              this.files.description = el.category
            })
            this.files2 = new file()
            this.fileUploadedList2.filter(x => x.type == "Second_olevel" && x.category == "student").forEach(el => {
              this.files2.id = el.id
              this.files2.filename = el.filename
              this.files2.description = el.category
            })
            this.oLevelLoading = false
          }
          this.oLevelLoading = false
        }
        else {
          // this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['data'])
          this.oLevelLoading = false
        }
      }, err => {
        this.oLevelLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }



}
