import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from 'src/app/api/app.service';
import { Constants } from 'src/app/helper/constants';


@Component({
  selector: 'app-logins',
  templateUrl: './logins.component.html',
  styleUrls: ['./logins.component.css']
})
export class LoginsComponent implements OnInit {
  // loginForm : FormGroup
  // password: any = 'admin'
  // username: string = 'examiner@gmail.com'
  password: any;
  username: string;
  modalClosed: boolean = false
  loading: boolean = false


  // @Output() public toggle : EventEmitter<any> = new EventEmitter<any>()

  constructor(private service: AppService, private route: Router) { }
  ngOnInit(): void {
    // this.loginForm = this.fb.group({
    //   'username':["", Validators.required],
    //   'password' : ["", Validators.required]
    // })
  }

  toggleForm() {
    this.modalClosed = !this.modalClosed
  }

  entranceLogin() {
    this.loading = true
    this.service.adminService.entranceLogin(this.username, this.password)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.service.helperService.removeFromStore(Constants.LOGIN_USER)
          this.service.helperService.saveToStore(Constants.LOGIN_USER, rs['data'])
          this.route.navigateByUrl("/home")
          this.loading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['message'])
        } else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['message'])
          console.log(rs['data'])
          this.loading = false
        }
      }, error => {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, "error")
        console.log(error)
        this.loading = false
      })
  }


}
