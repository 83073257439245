import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-multi-payment',
  templateUrl: './add-multi-payment.component.html',
  styleUrls: ['./add-multi-payment.component.css']
})
export class AddMultiPaymentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
