import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/api/app.service';
import { getSession } from 'src/app/helper/date_format';

@Component({
  selector: 'app-course-list',
  templateUrl: './course-list.component.html',
  styleUrls: ['./course-list.component.css']
})
export class CourseListComponent implements OnInit {
  semester: any
  level: any
  programme: any
  session : any
  showAddCourses: boolean = false
  deptDetails: any
  courseListByDept: any
  coursesListsID: any

  semesters = [{ name: 'First' }, { name: 'Second' }]
  programmes = [{ name: 'ND' }, { name: 'HND' }]
  levels = [{ name: 'I' }, { name: 'II' }]
  sessions = getSession()

  // @Input() deptDetails

  courseloading: boolean;
  errCourse: boolean;
  courses: any[];

  constructor(private service: AppService,) {
  }

  ngOnInit(): void {
    // this.getCoursesByDeptId()
  }

  showAddCourseList() {
    this.showAddCourses = true //!this.showAddCourses
    this.coursesListsID = null
  }

  hideAddCourseList() {
    this.showAddCourses = false
  }

  edit(courseListByDept) {
    this.coursesListsID = courseListByDept.id
    this.showAddCourses = true
  }

  clearAll() {
    this.courseloading = false
    this.errCourse = false
    this.deptDetails = null
    this.courseListByDept = null
    this.coursesListsID = null
    this.showAddCourses = false
    this.semester = null
    this.level = null
    this.programme = null
    this.courses= null
  }

  getCoursesByDeptId(dept) {
    this.deptDetails = dept

    if (dept.id) {

      this.courseloading = true
      this.errCourse = false
      this.service.studentService.getCoursesByDeptId(dept.id)
        .subscribe(res => {
          if (res['message'] == this.service.SUCCESS) {
            this.courseListByDept = res['data']
            console.log(this.courseListByDept)
            this.courseloading = false
          }
          else {
            this.courseloading = false
            this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          }
        }, err => {
          this.errCourse = true
          this.courseloading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        })
    }
    else {
      this.deptDetails = null
      this.courseListByDept = null
      this.hideAddCourseList()
    }

  }

  getCourseListsFormDetailsByDeptIdProgSemester() {
    // if (this.courseForm.valid) {
      this.courseloading = true
      this.errCourse = false
      this.courseListByDept = null
      this.service.studentService.getCourseListsFormDetails(this.session, this.deptDetails?.id)
        .subscribe(res => {          
            if (res['message'] == this.service.SUCCESS) {
              // console.log(res['data'])
              this.courseListByDept = [res['data']][0]
              this.courseloading = false
            }
            else {
              this.courseloading = false
              this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
            }
          }, err => {
            // console.log(err)
            this.errCourse = true
            this.courseloading = false
            this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
          })
        }

  

}
