import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-viewstudentdetails',
  templateUrl: './viewstudentdetails.component.html',
  styleUrls: ['./viewstudentdetails.component.css']
})
export class ViewstudentdetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  
}
