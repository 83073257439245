
import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { AppService } from 'src/app/api/app.service';
import { CourseListComponent } from './course-list/course-list.component';

@Component({
  selector: 'app-courses',
  templateUrl: './courses.component.html',
  styleUrls: ['./courses.component.css']
})
export class CoursesComponent implements OnInit {
  departmentloading: boolean;
  errDepartment: boolean;
  departments: any;
  selectedValue: any
  deptDetails : any

  // @Output() private getDept:EventEmitter<any> = new EventEmitter()
  @ViewChild(CourseListComponent ) child: CourseListComponent ;   
  constructor(private service: AppService, private fb: FormBuilder, private _route: Router) { }

  ngOnInit(): void {
    this.getAvailableDepartment()
  }

  goback(){
    this._route.navigateByUrl("/home/settings" )
  }
  showDisplay(){
    // console.log(this.selectedValue)
    this.child.clearAll()
    this.deptDetails = this.selectedValue
    this.child.getCoursesByDeptId(this.deptDetails)    
    // this.tryOperation()
  }
  // tryOperation(){
  //   this.getDept.emit()
  // }


  getAvailableDepartment() {
    this.departmentloading = true
    this.errDepartment = false
    // this.departments = []
    this.service.adminService.getAvailableDepartment()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.departments = res['data']
          // console.log(res)
          this.departmentloading = false
        }
        else {
          this.departmentloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          // console.log(res['message'])
        }
      }, err => {
        this.departmentloading = false
        this.errDepartment = true
        // console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }
}
