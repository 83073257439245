import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../api/Base.service';
import { resetPassword } from '../model/entrance';
import { addCourseForm, approveReject, Staff } from '../model/staff';
import { addCourses, addSession, getAvailableDepartment } from '../model/student';

@Injectable({
    providedIn: 'root'
  })
  export class staffService{
    constructor(private http: HttpClient ,private base: BaseService) { }

    private baseUrl = this.base._baseUrl + '/lecturer'

    // createLecturerCourse(payload: createLecturerCourse): Observable<any>{
    //   return this.http.post<any>(this.baseUrl +
    //     `/create`, payload)
    //     .pipe(
    //     catchError(err => this.base.errorHandler(err))
    //   )
    // }
  
    // changePassword(payload: changePassword): Observable<any> {
    //   return this.http.post<any>(this.baseUrl +
    //     `/change-password`, payload)
    //     .pipe(
    //       catchError(err => this.base.errorHandler(err))
    //     )
    // }
    // getLecturersByStudents(): Observable<any> {
    //   return this.http.get<any>(this.baseUrl +
    //     `/get-lecturer-by-student`)
    //     .pipe(
    //       catchError(err => this.base.errorHandler(err))
    //     )
    // }
  
    // getLecturersbyAdmissionYear(admissionYear: string): Observable<any> {
    //   return this.http.get<any>(this.baseUrl +
    //     `/get-lecturers?admissionyear=${admissionYear}`)
    //     .pipe(
    //       catchError(err => this.base.errorHandler(err))
    //     )
    // }
    // getLecturersOnlybyAdmissionYear(admissionYear: string): Observable<any> {
    //   return this.http.get<any>(this.baseUrl +
    //     `/get-lecturesonly?admissionyear=${admissionYear}`)
    //     .pipe(
    //       catchError(err => this.base.errorHandler(err))
    //     )
    // }
    // getLecturersDetailsbyLecurerId(lecturer: number): Observable<any> {
    //   return this.http.get<any>(this.baseUrl +
    //     `/get-lecturere-courses-details?lecturer=${lecturer}`)
    //     .pipe(
    //       catchError(err => this.base.errorHandler(err))
    //     )
    // }
    // getStudentByLectIdProgLvlSemesterDeptId(admissionyear: string, lecturer: number, programme: string,semester : string, level: string, deptid: number ): Observable<any> {
    //   return this.http.get<any>(this.baseUrl +           
    //     `/get-lecturer-by-student?admissionyear=${admissionyear}&deptid=${deptid}&lecturer=${lecturer}&level=${level}&programme=${programme}&semester=${semester}`) //&deptid=${deptid}`)
    //     .pipe(
    //       catchError(err => this.base.errorHandler(err))
    //     )
    // }




    //  *************************** STAFF ()
    // addStaff(payload: Staff): Observable<any>{
    //     return this.http.post<any>(this.baseUrl +
    //       `/create-staff`, payload)
    //       .pipe(
    //       catchError(err => this.base.errorHandler(err))
    //     )
    //   }

      // approveRejectStudent(payload: approveReject): Observable<any>{
      //   return this.http.post<any>(this.baseUrl +
      //     `/approve-reject-student`, payload)
      //     .pipe(
      //     catchError(err => this.base.errorHandler(err))
      //   )
      // }
      // addDepartment(payload: getAvailableDepartment): Observable<any> {
      //   return this.http.post<any>(this.baseUrl +
      //     `/add-update/dept`, payload)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // addCourses(payload: addCourses): Observable<any> {
      //   return this.http.post<any>(this.baseUrl +
      //     `/add-courses`, payload)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // addCoursesForm(payload: addCourseForm): Observable<any> {
      //   return this.http.post<any>(this.baseUrl +
      //     `/course-form`, payload)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // addUpdateSession(payload: addSession): Observable<any> {
      //   return this.http.post<any>(this.baseUrl +
      //     `/add-update-session`, payload)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // updatePassword(payload: resetPassword): Observable<any> {
      //   return this.http.post<any>(this.baseUrl +
      //     `/reset-password`, payload)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // getSession(): Observable<any>{
      //   return this.http.get<any>(this.baseUrl +
      //     `/get-sessions`)
      //     .pipe(
      //     catchError(err => this.base.errorHandler(err))
      //   )
      // }

      // getDashboard(): Observable<any>{
      //   return this.http.get<any>(this.baseUrl +
      //     `/dashboard/data`)
      //     .pipe(
      //     catchError(err => this.base.errorHandler(err))
      //   )
      // }
      
      // getStudentByDeptId(id: number){
      //   return this.http.get<any>(this.baseUrl +
      //     `/student-by-deptid?deptid=${id}`)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }
      // getDeptById(id: number){
      //   return this.http.get<any>(this.baseUrl +
      //     `/get-dept-byid?id=${id}`)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }
   
      // getCoursesByDeptId(deptId: number){
      //   return this.http.get<any>(this.baseUrl +
      //     `/list-course-bydept?deptid=${deptId}`)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // getCourseByProgLevelSemester(level:string, programme: string, semester: string){
      //   return this.http.get<any>(this.baseUrl +
      //     `/getcourses-by-programsemester-level?level=${level}&programme=${programme}&semester=${semester}`)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      
      // getCourseListsFormDetailsByDeptIdProgSemester(deptid: number,level:string, programme: string, semester: string){
      //   return this.http.get<any>(this.baseUrl +
      //     `/courseform?deptid=${deptid}&level=${level}&programme=${programme}&semester=${semester}`)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // getCourseListsFormDetailsByID(id: number){
      //   return this.http.get<any>(this.baseUrl +
      //     `/courseform-by-id?id=${id}`)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // listStaff(): Observable<any>{
      //   return this.http.get<any>(this.baseUrl +
      //     `/list-staff`)
      //     .pipe(
      //     catchError(err => this.base.errorHandler(err))
      //   )
      // }

      // getAllCourse(): Observable<any> {
      //   return this.http.get<any>(this.baseUrl +
      //     `/get-all-courses`)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // getAvailableDepartment(): Observable<any> {
      //   return this.http.get<any>(this.baseUrl +
      //     `/get-available-course`)
      //     .pipe(
      //       catchError(err => this.base.errorHandler(err))
      //     )
      // }

      // getAvailablePin(): Observable<any>{
      //   return this.http.get<any>(this.baseUrl +
      //     `/getAvailable/pin`)
      //     .pipe(
      //     catchError(err => this.base.errorHandler(err))
      //   )
      // }

      // getAllPin(): Observable<any>{
      //   return this.http.get<any>(this.baseUrl +
      //     `/getpin`)
      //     .pipe(
      //     catchError(err => this.base.errorHandler(err))
      //   )
      // }

      // getUsedPin(): Observable<any>{
      //   return this.http.get<any>(this.baseUrl +
      //     `/getused/pin`)
      //     .pipe(
      //     catchError(err => this.base.errorHandler(err))
      //   )
      // }
      
      // deleteDepartment(id: number): Observable<any>{
      //   return this.http.delete<any>(this.baseUrl +
      //     `/delete/course?id=${id}`)
      //     .pipe(
      //     catchError(err => this.base.errorHandler(err))
      //   )
      // }
  }