import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { coursesFormList, addCourseForm } from 'src/app/model/staff';
import { AppService } from 'src/app/api/app.service';
import { getSession } from 'src/app/helper/date_format';

@Component({
  selector: 'app-add-course',
  templateUrl: './add-course.component.html',
  styleUrls: ['./add-course.component.css']
})
export class AddCourseComponent implements OnInit {

  courseForm: FormGroup
  courseListForm: FormGroup
  courseFormListtID: number = 0
  loadingRemove: boolean;
  semester = [{ name: 'First' }, { name: 'Second' }]
  programme = [{ name: 'ND' }, { name: 'HND' }]
  level = [{ name: 'I' }, { name: 'II' }]
  courseloading: boolean;
  errCourse: boolean;
  courses: any[] = [];
  courseFormLists: coursesFormList[] = []
  courseFormListt = new coursesFormList()
  addcourseFormsList: addCourseForm[] = []
  addcourseForm = new addCourseForm()
  addcourseFormID: number = 0
  // deptID: number = 0
  loading: boolean;
  selectedCity1: any
  coursesListsDetails: any;
  sessions = getSession()

  @Input() deptName
  @Input() deptID
  
  @Input() coursesListsID
  @Output() private hideAddCourseList: EventEmitter<any> = new EventEmitter()
  addcourseFormsListDetails: any
  constructor(private service: AppService, private confirmationService: ConfirmationService, private fb: FormBuilder, private _router: Router) { }

  ngOnInit(): void {
    // this.productService.getProductsSmall().then(data => this.products = data);
    this.courseForm = this.fb.group({
      'programme': ['', Validators.required],
      'semester': ['', Validators.required],
      'level': ['', Validators.required],
      'session': ['', Validators.required],
    })

    this.courseListForm = this.fb.group({
      'coursecode': [''],
      'courseid': [0],
      'coursetitle': ['', Validators.required],
      'unit': ['', Validators.required],
      'prequisite': [''],
      'ch': [''],
      'status': [''],
      'p': ['', Validators.required],
    })

    // this.getCourseByProgLevelSemester()
    this.getCourseListsFormDetailsByID()

  }

  goback() {
    this.hideAddCourseList.emit()
  }

  editCourseForm(index, courseFormList) {

    this.selectedCity1 = ({
      id: courseFormList.courseid,
      coursecodetitle: "[" + courseFormList.code + "] " + courseFormList.title,
      coursetitle: courseFormList.title,
      coursecode: courseFormList.code
    })
    this.courseListForm.get('coursetitle').setValue(this.selectedCity1)
    this.courseFormListtID = courseFormList.id
    this.courseListForm.get('ch').setValue(courseFormList.ch)
    this.courseListForm.get('prequisite').setValue(courseFormList.prequisite)
    this.courseListForm.get('p').setValue(courseFormList.p)
    this.courseListForm.get('status').setValue(courseFormList.status)
    this.courseListForm.get('unit').setValue(courseFormList.unit)

    this.courseFormLists.splice(index, 1)
  }

  removeCourseForm(index, courseFormList) {
    if (courseFormList?.id != 0) {
      // console.log("Not null ", courseFormList.id)
      this.confirm2(courseFormList)
    }
    else
      this.courseFormLists.splice(index, 1)
    // console.log(" null ", courseFormList.id)
  }

  confirm2(courseFormList) {
    this.confirmationService.confirm({
      message: 'Do you want to delete this record?',
      header: 'Delete [' + courseFormList.code + "] " + courseFormList.title,
      icon: 'pi pi-info-circle',
      accept: () => {
        // this.messageService.add({severity:'info', summary:'Confirmed', detail:'Record deleted'});
        this.service.showMessage(this.service.MSG_INFO, this.service.INFO_TITLE, this.service.PROCESS_DELETE)
        this.deleteCourse(courseFormList.id)
      },
      reject: (type) => {
        this.service.showMessage(this.service.MSG_INFO, this.service.CANCEL_TITLE, "Cancel")
      }
    });
  }

  deleteCourse(id) {
    let programme = this.courseForm.get('programme').value
    let semester = this.courseForm.get('semester').value
    let level = this.courseForm.get('level').value
    let sess = this.courseForm.get('session').value
    this.service.studentService.deleteCourseForm(sess,this.deptID, id,level, programme, semester )
    .subscribe(
      (res) => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['data'])
          this.loadingRemove = false
          this.getCourseListsFormDetailsByDeptIdProgSemester()

        }
        else {
          this.loadingRemove = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
          console.log(res['data'])
        }
      }, err => {
        this.loadingRemove = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
        console.log(err)
      })
  }

  getCourseListsFormDetailsByID() {
    if (this.coursesListsID != null) {
      this.courseloading = true
      this.errCourse = false
      this.service.studentService.getCourseListsFormDetailsByID(this.coursesListsID)
        .subscribe(res => {
          if (res['message'] == this.service.SUCCESS) {
            this.addcourseFormsListDetails = res['data']
            // console.log(res['data'], "EDIT DEPT")
            // this.courseFormLists = []
            this.courseFormLists = this.addcourseFormsListDetails?.courseList
            this.courseForm.get('level').setValue(this.addcourseFormsListDetails?.level)
            this.courseForm.get('programme').setValue(this.addcourseFormsListDetails?.programme)
            this.courseForm.get('semester').setValue(this.addcourseFormsListDetails?.semester)
            this.courseForm.get('session').setValue(this.addcourseFormsListDetails?.academicyear)
            this.addcourseFormID = this.addcourseFormsListDetails?.id
            this.deptID = this.addcourseFormsListDetails?.deptid
            this.courseloading = false
            this.getCourseByProgLevelSemester()
          }
          else {
            this.courseloading = false
            this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
            this.getCourseByProgLevelSemester()
          }
        }, err => {
          this.courseloading = false
          this.errCourse = true
          console.log(err)
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        })
    }
  }

  getCourseListsFormDetailsByDeptIdProgSemester() {
    if (this.courseForm.valid) {
      this.getCourseByProgLevelSemester()
      this.courseloading = true
      this.errCourse = false
      this.addcourseFormID = 0
      let level = this.courseForm.get('level').value
      let semester = this.courseForm.get('semester').value
      let programme = this.courseForm.get('programme').value
      let academicyear = this.courseForm.get('session').value
      this.service.studentService.getCourseListsFormDetailsByDeptIdProgSemester(academicyear, this.deptID, level, programme, semester)
        .subscribe(res => {
          if (res['message'] == this.service.SUCCESS) {
            this.addcourseFormsListDetails = res['data']
            console.log(res['data'])
            this.courseFormLists = this.addcourseFormsListDetails?.courseList
            this.courseForm.get('level').setValue(this.addcourseFormsListDetails?.level)
            this.courseForm.get('programme').setValue(this.addcourseFormsListDetails?.programme)
            this.courseForm.get('semester').setValue(this.addcourseFormsListDetails?.semester)
            this.courseForm.get('session').setValue(this.addcourseFormsListDetails?.academicyear)
            this.addcourseFormID = this.addcourseFormsListDetails?.id
            this.deptID = this.addcourseFormsListDetails?.deptid
            this.courseloading = false
          }
          else {
            this.courseloading = false; this.courseFormLists = []
            this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
            console.log(res['message'])
          }
        }, err => {
          this.courseloading = false
          this.errCourse = true
          this.courseFormLists = []
          console.log(err)
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        })

    }
  }

  getCourseByProgLevelSemester() {
    this.courseloading = true
    this.errCourse = false
    let level = this.courseForm.get('level').value
    let semester = this.courseForm.get('semester').value
    let programme = this.courseForm.get('programme').value

    this.courseloading = true
    this.errCourse = false
    this.courses = []
    this.service.adminService.getCourseByProgLevelSemester(level, programme, semester)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS && res['responseCode'] == '200') {
          let result = res['data']
          result.forEach(items => {
            this.courses.push({ id: items.id, coursecodetitle: "[" + items.coursecode + "] " + items.coursetitle, coursetitle: items.coursetitle, coursecode: items.coursecode })

          });

          this.courseloading = false
        }
        else {
          this.courseloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          // console.log(res['message'])
        }
      }, err => {
        this.courseloading = false
        this.errCourse = true
        console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
    // }
  }

  addCourseList() {
    // console.log(this.selectedCity1)
    let idd = this.selectedCity1.id
    this.courseListForm.get('coursecode').setValue(this.selectedCity1.coursecode)
    this.courseListForm.get('coursetitle').setValue(this.selectedCity1.coursetitle)

    this.courseFormListt = new coursesFormList()
    this.courseFormListt.code = this.courseListForm.get('coursecode').value
    this.courseFormListt.title = this.courseListForm.get('coursetitle').value
    this.courseFormListt.ch = this.courseListForm.get('ch').value
    this.courseFormListt.p = this.courseListForm.get('p').value
    this.courseFormListt.prequisite = this.courseListForm.get('prequisite').value
    this.courseFormListt.status = this.courseListForm.get('status').value
    this.courseFormListt.unit = +this.courseListForm.get('unit').value
    this.courseFormListt.id = this.courseFormListtID
    this.courseFormListt.courseid = idd
    // console.log(this.courseListForm.get('courseid').value)
    // console.log(idd)
    this.courseFormLists.push(this.courseFormListt)
    this.courseListForm.reset()
  }


  addCourseFormList() {
    this.loading = true
    this.addcourseForm = new addCourseForm
    this.addcourseForm.courseList = this.courseFormLists
    this.addcourseForm.dept = this.deptID
    this.addcourseForm.level = this.courseForm.get('level').value
    this.addcourseForm.programme = this.courseForm.get('programme').value
    this.addcourseForm.semester = this.courseForm.get('semester').value
    this.addcourseForm.academicyear = this.courseForm.get('session').value
    this.addcourseForm.id = this.addcourseFormID

    console.log(this.addcourseForm)
    this.service.studentService.addCoursesForm(this.addcourseForm)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['data'])
          console.log("saved")
          this.getCourseListsFormDetailsByDeptIdProgSemester()
          this.loading = false
        } else {
          this.loading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['data'])
          console.log("not saved", rs['data'])
        }
      }, error => {
        this.loading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MSG_ERROR)
        console.log(error)
      })

  }
}
