import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { fileUploaded } from 'src/app/model/file';
import { addJambDatils, jambRequestList, jambsubjectsList, hndRequestList, ndResult, file } from 'src/app/model/student';
import { AppService } from 'src/app/api/app.service';


@Component({
  selector: 'app-jamb',
  templateUrl: './jamb.component.html',
  styleUrls: ['./jamb.component.css']
})
export class JambComponent implements OnInit {

  jambForm: FormGroup
  ndForm: FormGroup
  ndLoading: boolean = false
  jambLoading: boolean = false
  studentId: number

  // ****************  JAMB DETAILS ***************
  totalJamb: number
  addJambDatil = new addJambDatils()
  jambRequestlist = new jambRequestList()
  jambsubjectslist = new jambsubjectsList()
  jambsubjectsLists: jambsubjectsList[] = []
  jambRequestlists: jambRequestList[] = []
  loadingJamb: boolean = false
  jambID: number = 0
  //***************  ND RESULT *******
  hndRequestlist = new hndRequestList
  hndRequestlists: hndRequestList[] = []
  ndResult = new ndResult()
  loadingNDResult: boolean = false
  ndResultID: number = 0
  public show: boolean = false;
  public buttonName: any = 'Show';
  is_jambite: boolean

  @Input() show_footer: boolean;
  @Output() public next: EventEmitter<any> = new EventEmitter<any>()
  @Output() public back: EventEmitter<any> = new EventEmitter<any>()
  sub: any;
  pageLoading: boolean = false;
  isCompleted: boolean;
  loadingUpload: boolean;
  File: File
  files: file;
  modalClosed: boolean;
  fileName: any;
  loadingRemove: boolean;
  fileUploadedList: fileUploaded[] = []
  grade: any;
  jambError: boolean;
  loadingSubject: boolean;
  subjectMessage: string;
  constructor(private fb: FormBuilder, private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })

    this.grade = [
      { name: 'Distinction' },
      { name: 'Upper Credit' },
      { name: 'Lower Credit' },
      { name: 'Pass' },
      { name: 'Fail' },
    ]
  }

  ngOnInit(): void {

    this.jambForm = this.fb.group({
      'sub1': ['', Validators.required],
      'sub2': ['', Validators.required],
      'sub3': ['', Validators.required],
      'sub4': ['', Validators.required],
      'score1': [0, Validators.compose([Validators.required, Validators.maxLength(3), Validators.max(100)])],
      'score2': [0, Validators.compose([Validators.required, Validators.maxLength(3), Validators.max(100)])],
      'score3': [0, Validators.compose([Validators.required, Validators.maxLength(3), Validators.max(100)])],
      'score4': [0, Validators.compose([Validators.required, Validators.maxLength(3), Validators.max(100)])],
      'totalScore': [0, Validators.compose([Validators.required, Validators.maxLength(3), Validators.max(400)])],
      'regNo': ['', Validators.required],
      'examYear': ['', Validators.required],
    })
    this.ndForm = this.fb.group({
      'ndResult': ['', Validators.compose([Validators.required])],
      'cgpa': ['', Validators.compose([Validators.required])],
      'examYrs': ['', Validators.compose([Validators.required])],
    })
    this.getOlevelSubject()
    this.getMethod()
  }

  openDialog() {
    this.files = new file()
    this.modalClosed = !this.modalClosed
  }

  selectFile(fileInput: any) {
    this.File = <File>fileInput.target.files[0];
    this.fileName = fileInput.target.files[0].name
    //this.uploadDoc();
  }

  nextPage() {
    this.next.emit()
  }

  prevPage() {
    this.back.emit()
  }

  toggle() {
    this.show = !this.show;
  }

  autoAddScore() {
    let form = this.jambForm
    let st = +form.get('score1').value
    let nd = +form.get('score2').value
    let rd = +form.get('score3').value
    let th = +form.get('score4').value
    this.totalJamb = st + nd + rd + th
  }

  addJambDatils() {
    this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_SAVING, "")
    this.loadingJamb = true
    let form = this.jambForm
    this.jambsubjectsLists = []
    for (let i = 1; i < 5; i++) {
      this.jambsubjectslist = new jambsubjectsList()
      this.jambsubjectslist.scores = +form.get('score' + i.toString()).value
      this.jambsubjectslist.subject = form.get('sub' + i.toString()).value
      this.jambsubjectsLists.push(this.jambsubjectslist)
    }
    this.jambRequestlists = []
    this.jambRequestlist = new jambRequestList()
    this.jambRequestlist.regNo = form.get('regNo').value
    this.jambRequestlist.subjectsList = this.jambsubjectsLists
    this.jambRequestlist.yrExams = form.get('examYear').value
    this.jambRequestlist.id = +this.jambID
    this.jambRequestlists.push(this.jambRequestlist)
    this.addJambDatil.jambRequestList = this.jambRequestlists
    this.addJambDatil.studentid = this.studentId
    // this.jambID = this.studentId
    this.service.studentService.addJambDatils(this.addJambDatil)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['message'])
          this.loadingJamb = false

          this.getJamb();
        } else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['message'])
          this.loadingJamb = false
        }
      }, error => {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        this.loadingJamb = false
      })
  }
  getMethod() {
    this.getJamb()
    this.getNDResult()
    this.getAttachFile()
    this.getRegStage()
  }

  getOlevelSubject() {  
    this.loadingSubject = true
        this.subjectMessage = ''
        this.sub = []
    this.service.studentService.getOlevelSubject()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
        this.loadingSubject = false
        this.subjectMessage = ''
          this.sub = res['data']
        }
        else {
        this.loadingSubject = false
        this.subjectMessage = res['data'] //this.service.FAILED
        this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['data'])

      }
    }, err => {
        this.subjectMessage = this.service.ERROR_TITLE
        this.loadingSubject = false
      this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  reg_stage : number
  getRegStage() {
    
    this.service.studentService.getRegStage(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          if (res['data'] == 10) {
            this.isCompleted = true
            this.reg_stage =10
          }
          else
            this.isCompleted = false
            this.reg_stage = res['data']
        }
        else {
          this.isCompleted = false
        }
      }, err => {
        this.isCompleted = false
      })
  }
  
  addNdResult() {
    this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_SAVING, "")

    this.loadingNDResult = true

    let form = this.ndForm
    this.hndRequestlists = []
    this.hndRequestlist = new hndRequestList()
    this.hndRequestlist.grade = form.get('cgpa').value
    this.hndRequestlist.ndresult = form.get('ndResult').value
    this.hndRequestlist.yrExamination = form.get('examYrs').value
    this.hndRequestlist.id = + this.ndResultID
    this.hndRequestlists.push(this.hndRequestlist)

    this.ndResult.hndRequestList = this.hndRequestlists
    this.ndResult.studentid = this.studentId
    this.service.studentService.addNDResult(this.ndResult)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.loadingNDResult = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['message'])
          this.getMethod()
        } else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['message'])
          this.loadingNDResult = false

        }
      }, error => {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        this.loadingNDResult = false
      })
  }


  uploadDoc() {
    this.service.showMessage(this.service.MSG_INFO, this.service.INFO_TITLE, this.service.PROCESS_UPLOAD)
    this.loadingUpload = true
    let id = this.service.helperService.getUserId()
    let category = "student"
    let formData = new FormData();
    formData.append('file', this.File, this.File.name);

    this.service.fileService.fileUpload(id, category, "testimonial", formData).subscribe(
      (res) => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.loadingUpload = false
          this.files = new file()
          this.files.description = res['data'].category;
          this.files.filename = res['data'].filename;
          this.files.id = res['data'].id;
          this.closeModal()

        }
        else {
          this.loadingUpload = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.closeModal()

        }
      }, err => {
        this.loadingUpload = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        this.closeModal()

      })
  }

  closeModal() {
    this.modalClosed = false
  }

  getNDResult() {
    // this.pageLoading = true
    this.ndLoading = true
    let form = this.ndForm
    this.service.studentService.getNDResult(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          // this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['data'])
          this.hndRequestlist = new hndRequestList()
          if (res['data'].length > 0 && res['data'][0].id != null) {
            this.hndRequestlist = res['data'][0]
            form.get('cgpa').setValue(this.hndRequestlist?.grade)
            form.get('ndResult').setValue(this.hndRequestlist?.ndresult)
            form.get('examYrs').setValue(this.hndRequestlist?.yrExamination)
            this.ndResultID = this.hndRequestlist?.id
          }
          this.pageLoading = false
          this.ndLoading = false
        }
        else {
          this.pageLoading = false
          this.ndLoading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['data'])
        }
      }, err => {
        this.pageLoading = false
        this.ndLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  getJamb() {
    // this.pageLoading = true
    this.jambLoading = true
    let form = this.jambForm
    this.service.studentService.getJamb(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          if (res['data'].length > 0 && res['data'][0].id != null) {
            let jambRequestlist = res['data'][0]

            form.get('regNo').setValue(jambRequestlist.regNo)
            form.get('examYear').setValue(jambRequestlist.yrExams)
            this.jambsubjectsLists = jambRequestlist.subjectsList
            this.jambID = this.jambRequestlist.id

            let i = 1
            this.jambsubjectsLists.forEach(en => {
              form.get('score' + i.toString()).setValue(en.scores)
              form.get('sub' + i.toString()).setValue(en.subject)
              i++
            })
            this.autoAddScore();
            this.is_jambite = true
          }
          else {
            this.is_jambite = false
          }
          this.pageLoading = false
          // this.jambError = false
          this.jambLoading = false
        }
        else {
          this.jambLoading = false
          this.pageLoading = false
          this.is_jambite = false
          // this.jambError = true
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['data'])
        }
      }, err => {
        this.jambLoading = false
        // this.jambError = true
        this.is_jambite = false
        this.pageLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  remove(filename) {
    this.loadingRemove = true

    this.service.fileService.deleteFile(filename).subscribe(
      (res) => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])

          this.loadingRemove = false
          this.files = new file()
        }
        else {
          this.loadingRemove = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
        }
      }, err => {
        this.loadingRemove = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  getAttachFile() {
    // this.pageLoading = true 
    this.ndLoading = true
    this.fileUploadedList = []
    this.service.fileService.getAttachFile(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          if (res['data'].length > 0 && res['data'][0].id != null) {
            this.fileUploadedList = res['data']
            this.files = new file()
            this.fileUploadedList.filter(x => x.type == "testimonial" && x.category == "student").forEach(el => {
              this.files.id = el.id
              this.files.filename = el.filename
              this.files.description = el.category
            })
          }
          this.ndLoading = false
        }
        else {
          // this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['data'])
          this.ndLoading = false
        }
      }, err => {
        this.ndLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }


}
