import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-fees-view',
  templateUrl: './fees-view.component.html',
  styleUrls: ['./fees-view.component.css']
})
export class FeesViewComponent implements OnInit {

 
  pageloading: boolean
  errOccured: boolean = false
  student_id: number
  schoolfeeRequestList: any
  schorlarship: any
  schorshipreason: any
  institutionsList: any[] = []

  @Input() is_refresh : boolean
  
  constructor(private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.student_id = param.studentid
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['is_refresh']) {
      this.getStudentDetails()
    }
  }

  ngOnInit(): void {
    this.getStudentDetails()
  }

  getStudentDetails() {
    this.errOccured = false
    this.pageloading = true
    this.service.studentService.getStudentDetails(this.student_id)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.pageloading = false;
          this.schoolfeeRequestList = res['data'].schoolfeeRequestList[0]
          this.institutionsList = res['data'].institutionsList
        } else {
          this.pageloading = false;
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
        }
      }, err => {
        this.errOccured = true
        this.pageloading = false;
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)

      })
  }

}
