export class Staff{
  address?: string;
  comment?: string;
  dept?: any;
  designation?: string;
  email?: string;
  fullname?: string;
  id : number;
  password?: string;
  phone?: string;
  type?: string
}

export class addCourseForm {
  academicyear : string
  courseList: coursesFormList[]
  // datecreated: any
  dept: number
  id: number
  level: string
  programme: string
  semester: string
}

export class coursesFormList {
  ch: string
  code: string
  id: number
  p: string
  courseid : number
  prequisite: string
  status: string
  title: string
  unit: number
}

export class approveReject{
  approval : number
  approveddept : string
  comment : string
  studentid : number
  status : string
  faculty:string
  programme:string
}
export class updateDept{
  dept:string;
  faculty:string;
  student:number
}
export class getPins {
  pin: string
  status: string
  
}
export class getAvPins {
  pin: string
  status: string
  
}
export class getUPins {
  pin: string
  status: string
  
}