import { addSchoolFee, addSchoolFeeDataList, institutionAttd, institutionAttdDataList } from  'src/app/model/student';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppService } from 'src/app/api/app.service';


@Component({
  selector: 'app-fees',
  templateUrl: './fees.component.html',
  styleUrls: ['./fees.component.css']
})
export class FeesComponent implements OnInit {
  
  sub: any
  // public show: boolean = false;
  show: boolean = true;
  showing: any = "Yes";
  isScholarshipReason: string = ""
  public buttonName: any = 'Show';
  studentId: number
  countries: any[];
  citie: any
  personResponseForm: FormGroup
  institutionForm: FormGroup
  pageLoading: boolean = false
  schPgLoading: boolean = false
  loading: boolean = false
  institutionLoading: boolean = false


  addSchoolFeeDatalist = new addSchoolFeeDataList()
  addSchoolFeeDatalists: addSchoolFeeDataList[] = []
  addSchoolFee = new addSchoolFee()
  schoolFeeId: number = 0

  institutionAttdDatalists: any[] = []
  institutionAttdDatalist = new institutionAttdDataList()
  institutionAttd = new institutionAttd()
  institutionAttdId: number

  isCompleted: boolean;

  constructor(private fb: FormBuilder, private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })
  
    this.citie = [
      { name: 'WAEC SSCE' },
      { name: 'GCE O LEVEL' },
      { name: 'NECO' },
      { name: 'TECHNICAL COLLEGE' },
      { name: 'A LEVEL/OTHER' },
    ];

  }

  ngOnInit(): void {
    this.schoolFeeId = 0
    this.institutionAttdId = 0
    this.personResponseForm = this.fb.group({
      'name': ['', Validators.required],
      'relationship': ['', Validators.required],
      'contactAddress': ['', Validators.required],
      'phone': ['', Validators.compose([Validators.required, Validators.pattern("[0-9]{11}")])],
      'email': ['', Validators.email],

    })
    this.institutionForm = this.fb.group({
      'nameofschool': ['', Validators.required],
      'from': ['', Validators.required],
      'to': ['', Validators.required],
      'qualificationObtained': ['', Validators.required],
      'schoollocation': ['', Validators.required],
    })
    this.getDetails()    
  }

  isScholarship() {
    if (this.showing == "Yes" || this.showing == true) this.show = true
    else this.show = false
  }

  addInstitution() {
    let form = this.institutionForm

    this.institutionAttdDatalist = new institutionAttdDataList()
    this.institutionAttdDatalist.id = this.institutionAttdId
    this.institutionAttdDatalist.from = form.get('from').value
    this.institutionAttdDatalist.to = form.get('to').value
    this.institutionAttdDatalist.nameofschool = form.get('nameofschool').value
    this.institutionAttdDatalist.qualificationObtained = form.get('qualificationObtained').value.name
    this.institutionAttdDatalist.schoollocation = form.get('schoollocation').value
    this.institutionAttdDatalists.push(this.institutionAttdDatalist)
    form.reset()
  }

  removed(index, institutionAttdId) {
    if (institutionAttdId != 0) {
      this.removeInstitutionAttdId(institutionAttdId)
    }
    else {
      this.institutionAttdDatalists.splice(index, 1)
    }
  }

  removeInstitutionAttdId(id) {
    this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_DELETING, "")
    this.institutionLoading = true
    this.service.studentService.deleteInstitutionAttended(id, this.studentId)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['message'])
          this.institutionLoading = false
          this.getInstitutionAttend()
        } else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['message'])
          this.institutionLoading = false
        }
      }, error => {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        this.institutionLoading = false
      })
  }

  addInstitutionAttend() {
    this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_SAVING, "")

    // this.addPersonRespon()
    this.institutionAttd = new institutionAttd()
    this.institutionLoading = true
    this.institutionAttd.dataList = this.institutionAttdDatalists
    this.institutionAttd.studentid = this.studentId
    this.service.studentService.addinstitutionAttd(this.institutionAttd)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['message'])
          this.institutionLoading = false
          
        } else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['message'])
          this.institutionLoading = false

        }
      }, error => {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        this.institutionLoading = false
      })
  }

  addPersonRespon() {
    this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_SAVING, "")

    this.loading = true

    let form = this.personResponseForm
    this.addSchoolFeeDatalists = []
    this.addSchoolFeeDatalist = new addSchoolFeeDataList()
    this.addSchoolFeeDatalist.contactaddress = form.get('contactAddress').value
    this.addSchoolFeeDatalist.email = form.get('email').value
    this.addSchoolFeeDatalist.moblie = form.get('phone').value
    this.addSchoolFeeDatalist.name = form.get('name').value
    this.addSchoolFeeDatalist.relationship = form.get('relationship').value
    this.addSchoolFeeDatalist.scholarship = this.showing
    this.addSchoolFeeDatalist.schorlashipreason = this.isScholarshipReason
    this.addSchoolFeeDatalist.id = this.schoolFeeId
    this.addSchoolFeeDatalists.push(this.addSchoolFeeDatalist)

    this.addSchoolFee = new addSchoolFee()
    this.addSchoolFee.dataList = this.addSchoolFeeDatalists
    this.addSchoolFee.studentid = this.studentId
    this.service.studentService.addSchoolFee(this.addSchoolFee)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.loading = false
          this.addInstitutionAttend()
        } else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['message'])
          this.loading = false
        }
      }, error => {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        this.loading = false
      })
  }
  getDetails() {
    this.getSchoolFee()
    this.getInstitutionAttend()
  }

  getSchoolFee() {
    // this.pageLoading = true
    this.schPgLoading = true
    let form = this.personResponseForm
    this.service.studentService.getSchoolFee(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          // this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['data'])
          if (res['data'].length > 0 && res['data'][0].id != null) {
            let addSchoolFeeDatalist = new addSchoolFeeDataList()
            addSchoolFeeDatalist = res['data']

            form.get('contactAddress').setValue(addSchoolFeeDatalist[0]?.contactaddress)
            form.get('email').setValue(addSchoolFeeDatalist[0]?.email)
            form.get('phone').setValue(addSchoolFeeDatalist[0]?.moblie)
            form.get('name').setValue(addSchoolFeeDatalist[0]?.name)
            form.get('relationship').setValue(addSchoolFeeDatalist[0]?.relationship)
            this.showing = addSchoolFeeDatalist[0]?.scholarship
            this.isScholarshipReason = addSchoolFeeDatalist[0]?.schorlashipreason
            this.schoolFeeId = +addSchoolFeeDatalist[0].id
            this.isScholarship()
          }
          // this.pageLoading = false
          this.schPgLoading = false
          // this.getInstitutionAttend()
        }
        else {
          // this.pageLoading = false
          this.schPgLoading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, res['data'])

        }
      }, err => {
        // this.pageLoading = false
        this.schPgLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  getInstitutionAttend() {
    this.pageLoading = true
    let form = this.institutionForm
    this.institutionAttdDatalist = new institutionAttdDataList()

    this.service.studentService.getInstitutionAttend(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.institutionAttdDatalists = res['data']
          this.pageLoading = false

        }
        else {
          this.pageLoading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.SUCCESS_TITLE, res['data'])

        }
      }, err => {
        this.pageLoading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }


}
