import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppService } from 'src/app/api/app.service';
import { getSession } from 'src/app/helper/date_format';


@Component({
  selector: 'app-new-dashboard',
  templateUrl: './new-dashboard.component.html',
  styleUrls: ['./new-dashboard.component.css']
})
export class NewDashboardComponent implements OnInit {
  IsSession: boolean;
  session: string;
  deptsInfo: any
  deptsloading: boolean = false
  errDept: boolean = false
  dashBoarddetails: any

  depantmentalListInfo: any
  displayBasic: boolean;
  studentdeptsloading: boolean;
  errstudentDept: boolean;
  studentDeptdetails: any;
  deptname: string
  sessions = getSession()
  // sessionYear : string

  constructor(private service: AppService, private route: Router, public messageService: MessageService, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.checkSession()
    this.session = this.sessions[2].name
    this.dashBoardDetails(this.session)
  }

  checkSession() {
    this.IsSession = false
    this.session = ''
    this.service.adminService.getSession()
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.session = rs['data'][0].session
        } else {
          this.session = "Not availble"
          this.service.showMessage(this.service.MSG_ERROR, rs['message'], rs['data'])
        }
      }, error => {
        this.session = "Not availble"
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  showBasicDialog(id, dept) {
    this.displayBasic = true;
    this.studentdeptsloading = true
    this.errstudentDept = false
    this.getStudentByDept(id)
    this.deptname = "Department (" +dept +")"
  }
  getStudentByDept(id) {

    this.service.studentService.getStudentByDeptId(id,this.session)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.studentDeptdetails = rs['data']
          this.studentdeptsloading = false
        }
        else {
          this.studentdeptsloading = false
          this.service.showMessage(this.service.MSG_ERROR, rs['message'], rs['data'])
        }
      }, error => {
        this.studentdeptsloading = false
        this.errstudentDept = true
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }
  
  dashBoardDetails(sessionYear) {
    this.deptsloading = true
    this.errDept = false
    this.session = sessionYear
    this.service.dashboardService.getDashboard(this.session)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.dashBoarddetails = rs['data']
          this.depantmentalListInfo = this.dashBoarddetails?.depantmentalList
          // this.depantmentalListInfo.splice(0,1)
          this.deptsloading = false
          this.errDept = false
        }
        else {
          this.deptsloading = false
          this.errDept = false
          this.service.showMessage(this.service.MSG_ERROR, rs['message'], rs['data'])
        }
      }, error => {
        this.deptsloading = false
        this.errDept = true
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }
}
