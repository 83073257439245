import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BaseService } from 'src/app/api/Base.service';
import { resetPassword } from 'src/app/model/entrance';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit {
  pageLoading: boolean = false
  errOccured: boolean;
  errUpdate : boolean = false
  errUpload : boolean = false
  staffId: number
  getStaff: any
  imagePreview: any
  loadingImage: boolean = false
  file: File
  updatePasswordForm: FormGroup
  loadingUpdate: boolean = false

  constructor(private _route:Router, private service: AppService, private base: BaseService, private fb: FormBuilder) { 
    
  }

  ngOnInit(): void {
      
  }

  open(page){
    this._route.navigateByUrl("/home/settings/" + page)
  }

  // ngOnInit(): void {

  //   this.staffId = +this.service.helperService.getUserId()
  //   this.getStaff
  //   this.getUsersImage()
  //   this.updatePasswordForm = this.fb.group({
  //     'newPassword': ['', Validators.required],
  //     'confirmPassword': ['', Validators.required],
  //     'previouspassword': ['', Validators.required],
  //   })
  
  // }
  // setImage(fileInput: any) {
  //   this.file = <File>fileInput.target.files[0];
  //   const reader = new FileReader();
  //   reader.onload = () => {
  //     this.imagePreview = reader.result;
  //     this.uploadDoc()
  //   };
  //   reader.readAsDataURL(this.file);
  // }
  // updatePassword() {
  //   if (this.updatePasswordForm.get('newPassword').value == this.updatePasswordForm.get('confirmPassword').value) {
  //   this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_SAVING, '')
  //     let reset = new resetPassword()
  //     this.loadingUpdate = true
  //     reset.id = this.getStaff?.email
  //     // reset.id = this.listStaff?.email
  //     reset.newpassword = this.updatePasswordForm.get('newPassword').value
  //     reset.previouspassword = this.updatePasswordForm.get('previouspassword').value
  //     this.service.adminService.updatePassword(reset).subscribe(
  //       (res) => {
  //         if (res['message'] == this.service.SUCCESS) {
  //           this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['data'])
  //           // console.log(res)
  //           this.loadingUpdate = false
  //         }
  //         else {
  //           this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
  //           this.loadingUpdate = false
  //         }
  //       }, err => {
  //         this.loadingUpdate = false
  //         this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
  //         console.log(err)
  //       })
  //   }
  //   else {
  //     this.service.showMessage(this.service.MSG_WARNING, this.service.ERROR_TITLE, "Incorrect password")
  //     console.log("Err")
  //   }
  // }
  // uploadDoc() {
  //   this.service.showMessage(this.service.MSG_INFO, this.service.PROCESS_UPLOAD, this.service.PROCESS_SAVING)
  //   this.loadingImage = true
  //   let id = this.staffId
  //   let category = "staff"
  //   let formData = new FormData();
  //   formData.append('file', this.file, this.file.name);
  //   // console.log(formData, "Form")
  //   this.service.fileService.imageUpload(id, category, formData).subscribe(
  //     (res) => {
  //       if (res['message'] == this.service.SUCCESS) {
  //         this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['data'])
  //         // console.log(res['data'])
  //         this.loadingImage = false
  //       }
  //       else {
  //         this.loadingImage = false

  //         this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
  //         console.log(res['data'])
  //       }
  //     }, err => {
  //       this.loadingImage = false
  //       this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
  //       console.log(err)
  //     })
  // }
  // getUsersImage() {
  //   let userid = this.service.helperService.getUserId()
  //   let category = "staff"
  //   let baseUrl = this.base._baseUrl
  //   this.imagePreview = baseUrl + `/preview/photo?category=${category}&id=${userid}`

  // }
  // listStaff() {
  //   this.errOccured = false
  //   this.pageLoading = true
  //   this.service.adminService.listStaff(this.staffId)
  //     .subscribe(res => {
  //       if (res['message'] == this.service.SUCCESS) {
  //         this.getStaff = res['data']
  //         // console.log(res)
  //         this.pageLoading = false;
  //       } else {
  //         this.pageLoading = false;
  //         console.log(res['data'])
  //         this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
  //       }
  //     }, err => {
  //       this.pageLoading = false;
  //       this.errOccured = true
  //       console.log(err, "Err")
  //       this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, err)
  //     })
  // }

}
