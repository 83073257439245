import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { BaseService } from 'src/app/api/Base.service';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.css']
})
export class UserProfileComponent implements OnInit {

  pageLoading: boolean = false
  errOccured: boolean;
  errUpdate : boolean = false
  errUpload : boolean = false
  staffId: number
  getStaff: any
  imagePreview: any
  loadingImage: boolean = false
  file: File
  profileForm: FormGroup
  loadingUpdate: boolean = false
  genderList : any

  constructor(private service: AppService, private base: BaseService, private fb: FormBuilder) { }


  ngOnInit(): void {
    this.profileForm = this.fb.group({
      'fullname': ['', Validators.required],
      'phone': ['', Validators.required],
      'email': ['', Validators.required],
      'address': ['', Validators.required],
      'department': ['', Validators.required],
      'course': ['', Validators.required],
    })
  }

}
