import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../api/Base.service';
const headers = new HttpHeaders({
  'Content-Type': 'application/json'
})

@Injectable({
  providedIn: 'root'
})
export class FileManagerService {
  constructor(private http: HttpClient, private base: BaseService) { }

  private baseUrl = this.base._baseUrl
  
  downloadStudentForm(id:number):Observable<any> {
    return this.http.get<any>(this.baseUrl + 
      `/download?studentid=${id}`, 
      {headers:headers, responseType: 'blob' as 'json'}
      )
    .pipe(
      catchError( err =>  this.errorHandler(err))
    )
  }
  
  downloadStudentsExaminationClearance(level: string, semester: string, session: string, studentid: number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/download/clearance?level=${level}&semester=${semester}&session=${session}&studentid=${studentid}`,
      { headers: headers, responseType: 'blob' as 'json' }
    )
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }  

  downloadStudentAdmissionLetter(id:number):Observable<any> {
    return this.http.get<any>(this.baseUrl + 
      `/download/admission-letter?studentid=${id}`, 
      {headers:headers, responseType: 'blob' as 'json'}
      )
    .pipe(
      catchError( err =>  this.errorHandler(err))
    )
  }
  
  // fileUpload(id:number, category: string, file : any):Observable<any> {
  //   return this.http.post<any>(this.baseUrl + 
      
  //     `/file/upload?category=${category}&id=${id}`,file )
  //   .pipe(
  //     catchError( err =>  this.errorHandler(err))
  //   )
  // }
 

  downloadEnterprenuerStudent(session:string,course: string, level: string,semester:string): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/download/enterpreneur?admissionyear=${session}&course=${course}&level=${level}&semester=${semester}`,
      { headers: headers, responseType: 'blob' as 'json' }
    )
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

  getAttachFile(id:number): Observable<any> {
    return this.http.get<any>(this.baseUrl +
      `/get-attached-files?id=${id}`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }
  
  fileUpload(id:number, category: string, type:string, file : any):Observable<any> {
    return this.http.post<any>(this.baseUrl + 
      `/file/upload?category=${category}&id=${id}&type=${type}`,file)
    .pipe(
      catchError( err =>  this.errorHandler(err))
    )
  }
   
  fileMutliCourse(file : any):Observable<any> {
    return this.http.post<any>(this.baseUrl + 
      `/upload-excel`,file )
    .pipe(
      catchError( err =>  this.errorHandler(err))
    )
  }
  

  fileUploadCourse(file : any):Observable<any> {
    return this.http.post<any>(this.baseUrl + 
      `/upload-courses`,file )
    .pipe(
      catchError( err =>  this.errorHandler(err))
    )
  }

  deleteFile(filename : string): Observable<any>{
    return this.http.delete<any>(this.baseUrl +
      `/file/delete?filename=${filename}`)
      .pipe(
      catchError(err => this.errorHandler(err))
    )
  }

  errorHandler(error: HttpErrorResponse) {
    return Observable.throw(error.message || "Server Error")
  }

  imageUpload(id:number, category: string, file : any):Observable<any> {
    return this.http.post<any>(this.baseUrl + 
      `/upload/photograph?category=${category}&id=${id}`,file )
    .pipe(
      catchError( err =>  this.errorHandler(err))
    )
  } 
}
