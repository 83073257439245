import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppService } from '../api/app.service';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.css']
})
export class StudentComponent implements OnInit {

  constructor(private service: AppService, private _route: Router) { }

  ngOnInit(): void {
  }
  // wf

  goback() {
    this._route.navigateByUrl("/home/application")
  }


  // getRegStage() {
  //   let StudentId = this.service.helperService.getUserId();
  //   this.service.studentService.getRegStage(StudentId)
  //     .subscribe(res => {
  //       if (res['message'] == this.service.SUCCESS) {
  //         if (res['data'] == 10) {
  //           this.isCompleted = true
  //         }
  //         else
  //           this.isCompleted = false
  //       }
  //       else {
  //         this.isCompleted = false
  //       }
  //     }, err => {
  //       this.isCompleted = false
  //     })

  // }
}
