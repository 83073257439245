import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { addStudent } from 'src/app/model/student';
import { AppService } from 'src/app/api/app.service';
import { getSession } from 'src/app/helper/date_format';

@Component({
  selector: 'app-student-progress',
  templateUrl: './student-progress.component.html',
  styleUrls: ['./student-progress.component.css']
})
export class StudentProgressComponent implements OnInit {


  pageloading: boolean = false
  errOccured: boolean = false
  constructor(private service: AppService, private route: Router) { }
  students: addStudent[];
  selectedStudent: addStudent[];

  // selectedCustomers1: Customer[];
  // selectedCustomer: Customer;

  statuses: any[];

  rowGroupMetadata: any;

  activityValues: number[] = [0, 100];

  @ViewChild('dt') table: Table;
  sessions = getSession()
  session: string;
  
  ngOnInit(): void {
    this.session = this.sessions[2].name
    this.getAllStudents();

  }

  getAllStudents() {
    this.pageloading = true
    this.errOccured = false
    this.service.adminService.getByStatus(this.session, "on-going")
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.students = res['data']
          this.pageloading = false
        }
        else {
          this.pageloading = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['data'])
        }
      }, err => {
        this.pageloading = false
        this.errOccured = true
        console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
      })
  }

  getStudentDetails(studentid: number) {
    this.route.navigateByUrl("/home/student-details/" + studentid)
  }

  editStudentDetails(studentid: number) {
    this.route.navigateByUrl("/home/editstudent-details/" + studentid)
  }

}
