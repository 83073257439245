import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-menu',
    template: `
    <ul class="layout-menu layout-main-menu clearfix">
        <li app-menuitem *ngFor="let item of model; let i = index;" [item]="item" [index]="i" [root]="true"></li>
    </ul>
    `
})
export class AppMenuComponent implements OnInit {

    model: any[];

    ngOnInit() {
        this.model = [
            {
                items: [

                    {
                        label: 'Dashboards', icon: 'pi pi-fw pi-home', routerLink: ['/home'],
                    },
                    // { label: 'Application Forms', icon: 'pi pi-fw pi-bookmark', routerLink: ['/home/application'] },
                    { label: 'Applicant', icon: 'pi pi-fw pi-bookmark', routerLink: ['/home/application'] },
                    { label: 'Students', icon: 'pi pi-fw pi-user', routerLink: ['/home/student'] },
                    { label: 'Administrator', icon: 'pi pi-fw pi-user', routerLink: ['/home/users'] },
                    { label: 'Payment History', icon: 'pi pi-fw pi-calendar', routerLink: ['/home/payment'] },
                    { label: 'Entrepreneurship', icon: 'pi pi-fw pi-book', routerLink: ['/home/enterprenuer'] },
                    { label: 'Registration Pin', icon: 'pi pi-fw pi-book', routerLink: ['/home/registration-pin'] },

                    // { label: 'Department', icon: 'pi pi-fw pi-calendar-plus', routerLink: ['/home/department'] },
                    // { label: 'Course', icon: 'pi pi-fw pi-calendar', routerLink: ['/home/course'] },
                    // { label: 'Hostel', icon: 'pi pi-fw pi-home', routerLink: ['/home/hostel'] },
                    // { label: 'Library', icon: 'pi pi-fw pi-book', routerLink: ['/home/library'] },
                    // { label: 'Admission status', icon: 'pi pi-fw pi-check', routerLink: ['/home/admission_status'] },
                ]
            },
        ];
    }
}
