import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-error-state',
  templateUrl: './error-state.component.html',
  styleUrls: ['./error-state.component.css']
})
export class ErrorStateComponent implements OnInit {
  
  @Output() private retry:EventEmitter<any> = new EventEmitter()

  constructor() { }

  ngOnInit(): void {
  }

  tryOperation(){
    this.retry.emit()
  }

}
