import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';

import { AppWizardComponent } from './pages/app.wizard.component';
import { AppMainComponent } from './app.main.component';
import { AppNotfoundComponent } from './pages/app.notfound.component';
import { AppErrorComponent } from './pages/app.error.component';
import { AppAccessdeniedComponent } from './pages/app.accessdenied.component';
import { AllAdminComponent } from './core-app/all-admin/all-admin.component'; //'./filling/userform/userform.component';
import { LoginsComponent } from './core/logins/logins.component'; // /logins/logins.component';
import { CourseformComponent } from './filling/setting/courseform/courseform.component';

import { ViewstudentComponent } from './filling/viewstudent/viewstudent.component';
import { StudentviewComponent } from './studentview/studentview.component';
import { SettingComponent } from './filling/setting/setting.component';
import { PinsComponent } from './core-app/pins/pins.component';
import { ProfileComponent } from './filling/profile/profile.component';
import { SemesterComponent } from './filling/setting/semester/semester.component';
import { CoursesComponent } from './filling/setting/courses/courses.component';
import { NewDashboardComponent } from './core-app/new-dashboard/new-dashboard.component'; //'./new-dashboard/new-dashboard.component';

import { StudentComponent } from './student/student.component';
import { PaymentComponent } from './core-app/payment/payment.component';
import { PaymentHistoryComponent } from './core-app/payment/payment-history/payment-history.component';
import { EntrepreneurshipComponent } from './core-app/entrepreneurship/entrepreneurship.component';
import { OlevelComponent } from './filling/setting/olevel/olevel.component';
import { StudentsComponent } from './core-app/students/students.component';



@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'home', component: AppMainComponent,
                children: [
                    { path: '', component: NewDashboardComponent },
                    {path: 'users', component: AllAdminComponent},
                    { path: 'application', component: ViewstudentComponent },
                    { path: 'student', component: StudentsComponent },
                    {path: 'profile', component: ProfileComponent},
                    // {path: 'hostel', component: HostelComponent},
                    {path: 'registration-pin', component: PinsComponent},
                    // {path: 'library', component: LibraryComponent},
                    {path: 'payment', component: PaymentComponent},
                    {path: 'enterprenuer', component: EntrepreneurshipComponent},
                    {path: 'payment-history/:studentid', component: PaymentHistoryComponent},
                    { path: 'student-details/:studentid', component: StudentviewComponent}, 
                    { path: 'editstudent-details/:studentid', component: StudentComponent},                    
                    {path: 'settings',
                    children: [
                        { path: '', component: SettingComponent },
                        { path: 'semester', component: SemesterComponent },
                        {path: 'all-course', component: CourseformComponent},
                        { path: 'courses', component: CoursesComponent },
                        { path: 'olevel', component: OlevelComponent },
                    ]
                },
              
                ]
            },

            { path: 'error', component: AppErrorComponent },
            { path: 'accessdenied', component: AppAccessdeniedComponent },
            { path: 'notfound', component: AppNotfoundComponent },
            { path: 'login', component: LoginsComponent },
            { path: '', component: LoginsComponent },
            { path: 'wizard', component: AppWizardComponent },
            { path: '**', redirectTo: '/notfound' },
        ], { scrollPositionRestoration: 'enabled' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
