import { Component, OnInit } from '@angular/core';
import { PersonalDetailsComponent } from '../edit/personal-details/personal-details.component';
import { FeesViewComponent } from './fees-view/fees-view.component';
import { JambViewComponent } from './jamb-view/jamb-view.component';
import { OlevelViewComponent } from './olevel-view/olevel-view.component';
import { PersonalViewComponent } from './personal-view/personal-view.component';
import { ViewDeclarationComponent } from './view-declaration/view-declaration.component';

@Component({
  selector: 'app-printing',
  templateUrl: './printing.component.html',
  styleUrls: ['./printing.component.css']
})
export class PrintingComponent implements OnInit {

  is_refresh: boolean = false
  constructor() { }

  ngOnInit(): void {
  }

  refresh() {
    this.is_refresh = ! this.is_refresh        
  }
}
