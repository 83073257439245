import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-view-declaration',
  templateUrl: './view-declaration.component.html',
  styleUrls: ['./view-declaration.component.css']
})
export class ViewDeclarationComponent implements OnInit {

  studentId: any;
  fullname: any;
  pageloading: boolean;
  relationship: any;
  parentName: any;
  errOccured: boolean;
  parentGuardianDataLst: any

  @Input() show_footer: boolean;
  @Input() is_refresh: boolean
  selectedStudent: any;
  constructor(private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['is_refresh']) {
      this.getParentalGuardian()
      this.get_student_details()
    }
  }

  ngOnInit(): void {

    // this.fullname = this.service.helperService.getUserName()
    this.getParentalGuardian()
    this.get_student_details()

  }

  get_student_details() {
    this.pageloading = true
    this.errOccured = false
    this.service.studentService.getStudentDetails(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.pageloading = false
          this.fullname = res['data'].fullname
        }
        else {
          this.pageloading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
        }
      }, err => {
        this.pageloading = false
        this.errOccured = true
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }
  getParentalGuardian() {
    this.pageloading = true
    this.errOccured = false
    this.service.studentService.getParentGuardian(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          // this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['data'])
          this.parentGuardianDataLst = res['data'][0]
          this.relationship = this.parentGuardianDataLst?.relationship
          this.parentName = this.parentGuardianDataLst?.fullname
          this.pageloading = false
        }
        else {
          this.pageloading = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['data'])
        }
      }, err => {
        this.errOccured = true
        this.pageloading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

}
