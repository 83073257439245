import { Injectable } from '@angular/core';
import { Constants } from './constants';

@Injectable({
  providedIn: 'root'
})
export class HelperService {

  constructor() { }
  
  public getUserId(){
    let rawid = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    let user_id = rawid.id
    return (+user_id)
  }

  public getUserRole(){
    let data = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    let role = data.type
    return (role)
  }

  public getUserName(){
    let data = JSON.parse(localStorage.getItem(Constants.LOGIN_USER));
    let fullname = data.fullname
    return (fullname)
  }

  public saveToStore(key:string, data:any){
    localStorage.setItem(key, JSON.stringify(data));
  }

  // public saveUserID(key:string, id:number){
  //   localStorage.setItem(key, JSON.stringify(id));
  // }


  public removeFromStore(key:string){
    localStorage.removeItem(key)
  }

  public getFromStore(key:string){
    return JSON.parse(localStorage.getItem(key))
  }

}
