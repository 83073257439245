import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/api/app.service';
import { approveReject, updateDept } from 'src/app/model/staff';


@Component({
  selector: 'app-change-proposecourse',
  templateUrl: './change-proposecourse.component.html',
  styleUrls: ['./change-proposecourse.component.css']
})

export class ChangeProposecourseComponent implements OnInit {
  studentId: any;
  isCompleted: boolean;
  end : string = "END"
  pageloading: boolean;
  errOccured: boolean;
  staffId: number;
  
  approveRejectForm: FormGroup
  approveReject = new approveReject()
  updateDept = new updateDept()
  loadingAcptRej: boolean;
  err: any;
  approveReason: string;
  faculty:string
  courseGiven: string;
  personalDetails: any;
  courses: any[];
  courseloading: boolean;

  constructor(private service: AppService, private router: ActivatedRoute ) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })
    this.staffId = +this.service.helperService.getUserId()
  }
  
  ngOnInit(): void {
    this.getAllCourseAvailable()    
  }


  getAllCourseAvailable() {
    this.courses = []
    this.service.adminService.getAvailableCourse()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.courses = res['data']
          this.courseloading = false
        }
        else {
          this.courseloading = false
          console.log(res['message'])
        }
      }, err => {
        this.courseloading = false  
      })
  }

  changeProposeCourse() {
    this.loadingAcptRej = true  
    this.updateDept.dept = this.courseGiven
    this.updateDept.faculty = this.faculty
 
    this.updateDept.student = +this.studentId
    //this.approveReject.approval = +this.staffId

    // console.log(this.approveReject)
    // this.service.adminService.approveRejectStudent(this.approveReject)
    this.service.adminService.updateDept(this.updateDept)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['message'])
          console.log("saved")
          this.loadingAcptRej = false          
        } else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['data'])
          console.log("not saved")
          this.loadingAcptRej = false
          this.errOccured = true
          this.err = rs['data']
        }
      }, error => {
        this.loadingAcptRej = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, error)
        console.log(error)
      })
  }

 

}
