import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HashLocationStrategy, LocationStrategy} from '@angular/common';
import {AppRoutingModule} from './app-routing.module';

import {AccordionModule} from 'primeng/accordion';
import {AutoCompleteModule} from 'primeng/autocomplete';
import {AvatarModule} from 'primeng/avatar';
import {AvatarGroupModule} from 'primeng/avatargroup';
import {BadgeModule} from 'primeng/badge';
import {BreadcrumbModule} from 'primeng/breadcrumb';
import {ButtonModule} from 'primeng/button';
import {CalendarModule} from 'primeng/calendar';
import {CardModule} from 'primeng/card';
import {CarouselModule} from 'primeng/carousel';
import {CascadeSelectModule} from 'primeng/cascadeselect';
import {ChartModule} from 'primeng/chart';
import {CheckboxModule} from 'primeng/checkbox';
import {ChipModule} from 'primeng/chip';
import {ChipsModule} from 'primeng/chips';
import {CodeHighlighterModule} from 'primeng/codehighlighter';
import {ConfirmDialogModule} from 'primeng/confirmdialog';
import {ConfirmPopupModule} from 'primeng/confirmpopup';
import {ColorPickerModule} from 'primeng/colorpicker';
import {ContextMenuModule} from 'primeng/contextmenu';
import {DataViewModule} from 'primeng/dataview';
import {DialogModule} from 'primeng/dialog';
import {DividerModule} from 'primeng/divider';
import {DropdownModule} from 'primeng/dropdown';
import {FieldsetModule} from 'primeng/fieldset';
import {FileUploadModule} from 'primeng/fileupload';
import {FullCalendarModule} from 'primeng/fullcalendar';
import {GalleriaModule} from 'primeng/galleria';
import {InplaceModule} from 'primeng/inplace';
import {InputNumberModule} from 'primeng/inputnumber';
import {InputMaskModule} from 'primeng/inputmask';
import {InputSwitchModule} from 'primeng/inputswitch';
import {InputTextModule} from 'primeng/inputtext';
import {InputTextareaModule} from 'primeng/inputtextarea';
import {KnobModule} from 'primeng/knob';
import {LightboxModule} from 'primeng/lightbox';
import {ListboxModule} from 'primeng/listbox';
import {MegaMenuModule} from 'primeng/megamenu';
import {MenuModule} from 'primeng/menu';
import {MenubarModule} from 'primeng/menubar';
import {MessagesModule} from 'primeng/messages';
import {MessageModule} from 'primeng/message';
import {MultiSelectModule} from 'primeng/multiselect';
import {OrderListModule} from 'primeng/orderlist';
import {OrganizationChartModule} from 'primeng/organizationchart';
import {OverlayPanelModule} from 'primeng/overlaypanel';
import {PaginatorModule} from 'primeng/paginator';
import {PanelModule} from 'primeng/panel';
import {PanelMenuModule} from 'primeng/panelmenu';
import {PasswordModule} from 'primeng/password';
import {PickListModule} from 'primeng/picklist';
import {ProgressBarModule} from 'primeng/progressbar';
import {ProgressSpinnerModule} from 'primeng/progressspinner';
import {RadioButtonModule} from 'primeng/radiobutton';
import {RatingModule} from 'primeng/rating';
import {RippleModule} from 'primeng/ripple';
import {ScrollPanelModule} from 'primeng/scrollpanel';
import {ScrollTopModule} from 'primeng/scrolltop';
import {SelectButtonModule} from 'primeng/selectbutton';
import {SidebarModule} from 'primeng/sidebar';
import {SkeletonModule} from 'primeng/skeleton';
import {SlideMenuModule} from 'primeng/slidemenu';
import {SliderModule} from 'primeng/slider';
import {SplitButtonModule} from 'primeng/splitbutton';
import {SplitterModule} from 'primeng/splitter';
import {StepsModule} from 'primeng/steps';
import {TabMenuModule} from 'primeng/tabmenu';
import {TableModule} from 'primeng/table';
import {TabViewModule} from 'primeng/tabview';
import {TagModule} from 'primeng/tag';
import {TerminalModule} from 'primeng/terminal';
import {TieredMenuModule} from 'primeng/tieredmenu';
import {TimelineModule} from 'primeng/timeline';
import {ToastModule} from 'primeng/toast';
import {ToggleButtonModule} from 'primeng/togglebutton';
import {ToolbarModule} from 'primeng/toolbar';
import {TooltipModule} from 'primeng/tooltip';
import {TreeModule} from 'primeng/tree';
import {TreeTableModule} from 'primeng/treetable';
import {VirtualScrollerModule} from 'primeng/virtualscroller';

import {AppCodeModule} from './app.code.component';
import {AppComponent} from './app.component';
import {AppMainComponent} from './app.main.component';
import {AppConfigComponent} from './app.config.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import {AppLoginComponent} from './pages/app.login.component';
import {AppMenuComponent} from './app.menu.component';
import {AppMenuitemComponent} from './app.menuitem.component';
import {AppTopBarComponent} from './app.topbar.component';
import {AppFooterComponent} from './app.footer.component';
import {AppProfileComponent} from './app.profile.component';
import {AppInvoiceComponent} from './pages/app.invoice.component';
import {AppCrudComponent} from './pages/app.crud.component';
import {AppCalendarComponent} from './pages/app.calendar.component';
import {AppTimelineDemoComponent} from './pages/app.timelinedemo.component';
import {AppHelpComponent} from './pages/app.help.component';
import {AppWizardComponent} from './pages/app.wizard.component';
import {DashboardDemoComponent} from './demo/view/dashboarddemo.component';
import {DashboardBankingComponent} from './demo/view/dashboardbanking.component';
import {FormLayoutDemoComponent} from './demo/view/formlayoutdemo.component';
import {FloatLabelDemoComponent} from './demo/view/floatlabeldemo.component';
import {InvalidStateDemoComponent} from './demo/view/invalidstatedemo.component';
import {InputDemoComponent} from './demo/view/inputdemo.component';
import {ButtonDemoComponent} from './demo/view/buttondemo.component';
import {TableDemoComponent} from './demo/view/tabledemo.component';
import {ListDemoComponent} from './demo/view/listdemo.component';
import {TreeDemoComponent} from './demo/view/treedemo.component';
import {PanelsDemoComponent} from './demo/view/panelsdemo.component';
import {OverlaysDemoComponent} from './demo/view/overlaysdemo.component';
import {MediaDemoComponent} from './demo/view/mediademo.component';
import {MenusDemoComponent} from './demo/view/menusdemo.component';
import {MessagesDemoComponent} from './demo/view/messagesdemo.component';
import {MiscDemoComponent} from './demo/view/miscdemo.component';
import {EmptyDemoComponent} from './demo/view/emptydemo.component';
import {ChartsDemoComponent} from './demo/view/chartsdemo.component';
import {FileDemoComponent} from './demo/view/filedemo.component';
import {DocumentationComponent} from './demo/view/documentation.component';
import {DisplayComponent} from './utilities/display.component';
import {ElevationComponent} from './utilities/elevation.component';
import {FlexboxComponent} from './utilities/flexbox.component';
import {GridComponent} from './utilities/grid.component';
import {IconsComponent} from './utilities/icons.component';
import {SpacingComponent} from './utilities/spacing.component';
import {TypographyComponent} from './utilities/typography.component';
import {TextComponent} from './utilities/text.component';
import {WidgetsComponent} from './utilities/widgets.component';

import {CountryService} from './demo/service/countryservice';
import {EventService} from './demo/service/eventservice';
import {NodeService} from './demo/service/nodeservice';
import {MenuService} from './app.menu.service';
import {CustomerService} from './demo/service/customerservice';
import {PhotoService} from './demo/service/photoservice';
import {ProductService} from './demo/service/productservice';
import {IconService} from './demo/service/iconservice';
import { NgxPrintModule } from 'ngx-print';

import { CoreComponent } from './core/core.component';
import { CoreAppComponent } from './core-app/core-app.component';
import { AllAdminComponent } from './core-app/all-admin/all-admin.component'; 
import { LoginsComponent } from './core/logins/logins.component';
import { NewDashboardComponent } from './core-app/new-dashboard/new-dashboard.component';
import { DepartmentformComponent } from './core-app/new-dashboard/departmentform/departmentform.component';
import { PinsComponent } from './core-app/pins/pins.component';
import { PaymentComponent } from './core-app/payment/payment.component';
import { AddPaymentComponent } from './core-app/payment/add-payment/add-payment.component';
import { AddMultiPaymentComponent } from './core-app/payment/add-multi-payment/add-multi-payment.component';
import { PaymentHistoryComponent } from './core-app/payment/payment-history/payment-history.component';






//'./logins/logins.component';
// import { ApplicationComponent } from './application/application.component';
// import { PersonalDetailsComponent } from './application/personal-details/personal-details.component';
// import { ExamTakenComponent } from './application/exam-taken/exam-taken.component';
// import { FeesComponent } from './application/fees/fees.component';
// import { DeclarationComponent } from './application/declaration/declaration.component';
// import { DashboardComponent } from './dashboard/dashboard.component';
import { FormsComponent } from './forms/forms.component';
import { CourseformComponent } from './filling/setting/courseform/courseform.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';

// import { AdmissionStatusComponent } from './filling/admission-status/admission-status.component';
// import { AppFormOneComponent } from './app-form-one/app-form-one.component';
// import { StudentMenuComponent } from './student-menu/student-menu.component';
import { ViewstudentComponent } from './filling/viewstudent/viewstudent.component';
import { ViewpersonaldetailsComponent } from './studentview/viewpersonaldetails/viewpersonaldetails.component';
import { StudentexaminationdetailsComponent } from './studentview/studentexaminationdetails/studentexaminationdetails.component';
import { ViewparentdetailsComponent } from './studentview/viewparentdetails/viewparentdetails.component';
import { ApprovalpageComponent } from './studentview/approvalpage/approvalpage.component';
import { ViewstudentdetailsComponent } from './studentview/viewstudentdetails/viewstudentdetails.component';
import { NotFoundComponent } from './misc/not-found/not-found.component';
import { LoadingComponent } from './misc/loading/loading.component';
import { ErrorStateComponent } from './misc/error-state/error-state.component';
import { EmptyStateComponent } from './misc/empty-state/empty-state.component';
import { StudentviewComponent } from './studentview/studentview.component';
import { AllstudentComponent } from './filling/viewstudent/allstudent/allstudent.component';
import { StudentProgressComponent } from './filling/viewstudent/student-progress/student-progress.component';
import { StudentApprovedComponent } from './filling/viewstudent/student-approved/student-approved.component';
import { StudentRejectedComponent } from './filling/viewstudent/student-rejected/student-rejected.component';
import { StudentCompleteComponent } from './filling/viewstudent/student-complete/student-complete.component';
import { SettingComponent } from './filling/setting/setting.component';
import { ChangepasswordComponent } from './filling/profile/changepassword/changepassword.component';
import { AllPinComponent } from './filling/pins/all-pin/all-pin.component';
import { UsedPinComponent } from './filling/pins/used-pin/used-pin.component';
import { AvailablePinComponent } from './filling/pins/available-pin/available-pin.component';
import { UserProfileComponent } from './filling/profile/user-profile/user-profile.component';
import { ProfileComponent } from './filling/profile/profile.component';
import { SemesterComponent } from './filling/setting/semester/semester.component';
import { CoursesComponent } from './filling/setting/courses/courses.component';
import { CourseListComponent } from './filling/setting/courses/course-list/course-list.component';
import { AddCourseComponent } from './filling/setting/courses/add-course/add-course.component';

import { PersonalDetailsComponent } from './student/edit/personal-details/personal-details.component';
import { FeesComponent } from './student/edit/fees/fees.component';
import { ExamTakenComponent } from './student/edit/exam-taken/exam-taken.component';
import { JambComponent } from './student/edit/jamb/jamb.component';
import { OverviewComponent } from './student/overview/overview.component';
import { StudentComponent } from './student/student.component';
import { FormHeadComponent } from './student/printing/form-head/form-head.component';
import { ChangeProposecourseComponent } from './student/edit/change-proposecourse/change-proposecourse.component';
import { PaidStudentComponent } from './core-app/payment/paid-student/paid-student.component';
import { EntrepreneurshipComponent } from './core-app/entrepreneurship/entrepreneurship.component';
import { PrintingComponent } from './student/printing/printing.component';
import { FeesViewComponent } from './student/printing/fees-view/fees-view.component';
import { JambViewComponent } from './student/printing/jamb-view/jamb-view.component';
import { ViewDeclarationComponent } from './student/printing/view-declaration/view-declaration.component';
import { PersonalViewComponent } from './student/printing/personal-view/personal-view.component';
import { OlevelViewComponent } from './student/printing/olevel-view/olevel-view.component';
import { OlevelComponent } from './filling/setting/olevel/olevel.component';
import { StudentsComponent } from './core-app/students/students.component';
import { GraduatedStudentComponent } from './core-app/students/graduated-student/graduated-student.component';
import { AllStudentComponent } from './core-app/students/all-student/all-student.component';
import { StayliteComponent } from './core-app/students/staylite/staylite.component';
import { FresherComponent } from './core-app/students/fresher/fresher.component';



@NgModule({
    imports: [
        NgxPrintModule,
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        AvatarModule,
        AvatarGroupModule,
        BadgeModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        CascadeSelectModule,
        ChartModule,
        CheckboxModule,
        ChipModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ConfirmPopupModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DividerModule,
        DropdownModule,
        FieldsetModule,
        FileUploadModule,
        FullCalendarModule,
        GalleriaModule,
        InplaceModule,
        InputNumberModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        KnobModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        ProgressSpinnerModule,
        RadioButtonModule,
        RatingModule,
        RippleModule,
        ScrollPanelModule,
        ScrollTopModule,
        SelectButtonModule,
        SidebarModule,
        SkeletonModule,
        SlideMenuModule,
        SliderModule,
        SplitButtonModule,
        SplitterModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TagModule,
        TerminalModule,
        TimelineModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        VirtualScrollerModule,
        AppCodeModule
    ],
    declarations: [
        AppComponent,
        AppMainComponent,
        AppMenuComponent,
        AppMenuitemComponent,
        AppTopBarComponent,
        AppFooterComponent,
        AppProfileComponent,
        AppConfigComponent,
        DashboardDemoComponent,
        DashboardBankingComponent,
        FormLayoutDemoComponent,
        FloatLabelDemoComponent,
        InvalidStateDemoComponent,
        InputDemoComponent,
        ButtonDemoComponent,
        TableDemoComponent,
        ListDemoComponent,
        TreeDemoComponent,
        PanelsDemoComponent,
        OverlaysDemoComponent,
        MediaDemoComponent,
        MenusDemoComponent,
        MessagesDemoComponent,
        MessagesDemoComponent,
        MiscDemoComponent,
        ChartsDemoComponent,
        EmptyDemoComponent,
        FileDemoComponent,
        DocumentationComponent,
        DisplayComponent,
        ElevationComponent,
        FlexboxComponent,
        GridComponent,
        IconsComponent,
        SpacingComponent,
        TypographyComponent,
        TextComponent,
        WidgetsComponent,
        AppNotfoundComponent,
        AppErrorComponent,
        AppAccessdeniedComponent,
        AppLoginComponent,
        AppCrudComponent,
        AppCalendarComponent,
        AppTimelineDemoComponent,
        AppInvoiceComponent,
        AppHelpComponent,
        AppWizardComponent,
        AllAdminComponent,
        LoginsComponent,
        // ApplicationComponent,
        // PersonalDetailsComponent,
        // ExamTakenComponent,
        // FeesComponent,
        // DeclarationComponent,
        // DashboardComponent,
        FormsComponent,
        DepartmentformComponent,
        CourseformComponent,
        AdminDashboardComponent,
        // AdmissionStatusComponent,
        // AppFormOneComponent,
        // StudentMenuComponent,
        ViewstudentComponent,
        ViewpersonaldetailsComponent,
        StudentexaminationdetailsComponent,
        ViewparentdetailsComponent,
        ApprovalpageComponent,
        ViewstudentdetailsComponent,
        NotFoundComponent,
        LoadingComponent,
        ErrorStateComponent,
        EmptyStateComponent,
        StudentviewComponent,
        AllstudentComponent,
        StudentProgressComponent,
        StudentApprovedComponent,
        StudentRejectedComponent,
        StudentCompleteComponent,
        SettingComponent,
        UserProfileComponent,
        ChangepasswordComponent,
        AllPinComponent,
        UsedPinComponent,
        AvailablePinComponent,
        PinsComponent,
        ProfileComponent,
        SemesterComponent,
        CoursesComponent,
        CourseListComponent,
        AddCourseComponent,    
        NewDashboardComponent,
        PersonalDetailsComponent,
        FeesComponent,
        ExamTakenComponent,
        JambComponent,
        OverviewComponent,
        StudentComponent,
        FormHeadComponent,
        ChangeProposecourseComponent,
        PaymentComponent,
        AddPaymentComponent,
        AddMultiPaymentComponent,
        PaymentHistoryComponent,
        CoreComponent,
        CoreAppComponent,
        AllAdminComponent,
        PaidStudentComponent,
        EntrepreneurshipComponent,
        PrintingComponent,
        FeesViewComponent,
        JambViewComponent,
        ViewDeclarationComponent,
        PersonalViewComponent,
        OlevelViewComponent,
        OlevelComponent,
        StudentsComponent,
        GraduatedStudentComponent,
        AllStudentComponent,
        StayliteComponent,
        FresherComponent,
    ],
    providers: [
        {provide: LocationStrategy, useClass: HashLocationStrategy},
        CountryService, CustomerService, EventService, IconService, NodeService,
        MessageService, ConfirmationService,
        PhotoService, ProductService, MenuService,NgxPrintModule
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
