import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { getDate } from 'src/app/helper/date_format';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-viewpersonaldetails',
  templateUrl: './viewpersonaldetails.component.html',
  styleUrls: ['./viewpersonaldetails.component.css']
})
export class ViewpersonaldetailsComponent implements OnInit {
  pageLoding: boolean = false
  errOccured: boolean = false
  studentId: number
  selectedStudent: any
  parentRequestList: any
  constructor(private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })
  }

  ngOnInit(): void {
    this.getStudentDetails()
  }

  getDate(value) {
    return getDate(value)
  }

  getStudentDetails() {
    this.pageLoding = true
    this.errOccured = false
    this.service.studentService.getStudentDetails(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.pageLoding = false;
          this.selectedStudent = res['data']
          this.parentRequestList = res['data'].parentRequestList[0]
          // console.log(res['data'])

        } else {
          // this.loading = false;
          console.log(res['data'])
          this.pageLoding = false;
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
        }
      }, err => {
        this.errOccured = true
        console.log(err, "Err")
        this.pageLoding = false;
        this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, err)
      })
  }

}

