import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from '../api/Base.service';
import { resetPassword } from '../model/entrance';
import { Staff } from '../model/staff';

@Injectable({
  providedIn: 'root'
})
export class EnteranceService {
  constructor(private http: HttpClient ,private base: BaseService) { }

  private baseUrl = this.base._baseUrl 
  
  // entranceLogin(username: string, password: any): Observable<any>{
  //     return this.http.get<any>(this.baseUrl + 
  //       `/login?password=${password}&username=${username}`)
  //       .pipe(
  //       catchError(err => this.base.errorHandler(err))
  //     )
  //   }

    // passwordReset(payload : resetPassword): Observable<any>{
    //   return this.http.post<any>(this.baseUrl + 
    //     `/password-reset`, payload)
    //     .pipe(
    //     catchError(err => this.base.errorHandler(err))
    //   )
    // }

    errorHandler(error:HttpErrorResponse){
      return Observable.throw(error.message || "Server Error") 
    } 
}
