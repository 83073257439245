import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AppService } from 'src/app/api/app.service';
import { getSession } from 'src/app/helper/date_format';

@Component({
  selector: 'app-all-student',
  templateUrl: './all-student.component.html',
  styleUrls: ['./all-student.component.css']
})
export class AllStudentComponent implements OnInit {

  errOccured: boolean = false
  students: [];
  selectedStudent = []
  statuses: any[];
  rowGroupMetadata: any;
  activityValues: number[] = [0, 100];
  @ViewChild('dt') table: Table;
  departments = [];
  departmentloading: boolean;

  semesters = [{ name: 'First' }, { name: 'Second' }]
  programmes = [{ name: 'ND' }, { name: 'HND' }]
  levels = [{ name: 'I' }, { name: 'II' }]
  sessions = getSession()

  level: string;
  session: string;
  programme : string
  department: string;
  pageloading: boolean;

  constructor(private service: AppService, private route: Router) { }
  ngOnInit(): void {
    // this.getAllStudents()
    this.getAvailableDepartment();
  }

  getAvailableDepartment() {
    this.departmentloading = true
    // this.errDepartment = false
    this.departments = []
    this.pageloading = true
    this.errOccured = false
    this.service.adminService.getAvailableDepartment()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.departments = res['data']
          this.departmentloading = false
          this.programme = this.programmes[0].name
          this.session = this.sessions[1].name
          this.department = this.departments[0].dept
          this.getStudentByDeptProgrammeSesion()
        }
        else {
          this.departmentloading = false
          this.pageloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          // console.log(res['message'])
        }
      }, err => {
        this.departmentloading = false
        this.errOccured = true
        // this.errDepartment = true
        this.pageloading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  getStudentByDeptProgrammeSesion() {
    if (this.department != null && this.programme  != null  && this.session != null) {
      this.pageloading = true
      this.errOccured = false
      this.students = []
      // console.log(this.department, this.programme, this.session)
      this.service.studentService.getStudentByDeptProgrammeSesion(this.department, this.programme, this.session)
        .subscribe(res => {
          if (res['message'] == this.service.SUCCESS) {
            this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
            this.students = res['data']
            this.pageloading = false
          }
          else {
            this.pageloading = false
            this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
            console.log(res['data'])
          }
        }, err => {
          this.pageloading = false
          this.errOccured = true
          console.log(err)
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
        })
    }
    else{
      this.students =[]     
      this.service.showMessage(this.service.MSG_INFO, this.service.ERROR_TITLE, "No Record Found") 
    }
  }

  getStudentPaymentHistory(studentid: number) {
    this.route.navigateByUrl("/home/payment-history/" + studentid)
  }

}
