import { EnteranceService } from './enterance.service';

import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { staffService } from './staff.service';
import { ConnectionService } from 'ng-connection-service';
import { MessageService } from 'primeng/api';
import { StudentService } from './student.service';
import { HelperService } from '../helper/helper.service';
import { FileManagerService } from './file-manager.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { AdminService } from './admin.service';
import { DashboardService } from './dashboard.service';
import { StudentRegService } from './student-reg.service';
// import { ExaminationService } from './examination.service';
@Injectable({
  providedIn: 'root'
})
export class AppService {
  isConnected: boolean;
  private isLoggedin: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  public SUCCESS = "Success"
  public FAILED = "FAILED"
  public MSG_INFO = "info"

  public MSG_SUCCESS = "success"
  public MSG_WARNING = "warning"
  public MSG_ERROR = "error"

  public SUCCESS_TITLE = "Operation Successful";
  public INFO_TITLE = "Please Wait!!!";
  public ERROR_TITLE = "Error occur"
  public CANCEL_TITLE = "Operation Canceled "

  public MESSAGE_SUCCESS = "Successful"
  public MESSAGE_ERROR = "Oop! could not process request at the moment"
  public MESSAGE_ERROR_S = "Oop! could not process request"
  public PROCESS_SAVING = "Saving..., " + this.INFO_TITLE
  public PROCESS_DELETING = "Deleting..., " + this.INFO_TITLE
  public PROCESS_DOWNLOAD = "Downloading, " + this.INFO_TITLE
  public PROCESS_UPLOAD = "Uploading, " + this.INFO_TITLE


  public PROCESS_DELETE = "Deleting, " // + this.INFO_TITLE  
  public INLINE_DISPLAY = "inline"
  public ATTACHMENT_DISPLAY = "attachment"
  public ERROR_MESSAGE = "Oop! could not process request at the moment"


  constructor(
    private connectionService: ConnectionService,
    public adminService: AdminService,
    public dashboardService: DashboardService,
    public entranceService: EnteranceService,
    public fileService: FileManagerService,
    public messageService: MessageService,
    public staffService: staffService,
    public studentService: StudentService,
    public studentRegService: StudentRegService,

    public helperService: HelperService,

  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.showMessage("success", "Connection Status", "You are now connected")
      }
      else {
        this.showMessage("error", "Connection Status",
          "You are offline, check your network settings")
      }
    })
  }

  showMessage(type: string, title: string, content: string) {
    this.messageService.add({ severity: type, summary: title, detail: content });
  }
  loginStatus(): Observable<boolean> {
    return this.isLoggedin.asObservable();
  }
}