import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { getAvPins } from 'src/app/model/staff';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-available-pin',
  templateUrl: './available-pin.component.html',
  styleUrls: ['./available-pin.component.css']
})
export class AvailablePinComponent implements OnInit {
  pageloading: boolean = false
  errOccured: boolean = false

  constructor(private service: AppService, private route: Router) { }
  pins: getAvPins[];
  statuses: any[];
  rowGroupMetadata: any;

  activityValues: number[] = [0, 100];

  @ViewChild('dt') table: Table;

  ngOnInit(): void {
    this.getAvailablePins();
  }
  getAvailablePins() {
    this.pageloading = true
    this.errOccured = false
    this.service.adminService.getAvailablePin()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.pins = res['data']
          this.pageloading = false
        }
        else {
          this.pageloading = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['data'])
        }
      }, err => {
        this.pageloading = false
        this.errOccured = true
        console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
      })
  }


}
