import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppService } from 'src/app/api/app.service';
import { getDate, getSession } from 'src/app/helper/date_format';
import { addPayemnt } from 'src/app/model/student';
import { PaymentHistoryComponent } from '../payment-history/payment-history.component';

@Component({
  selector: 'app-add-payment',
  templateUrl: './add-payment.component.html',
  styleUrls: ['./add-payment.component.css']
})
export class AddPaymentComponent implements OnInit {
  form: FormGroup = new FormGroup({})
  loading: string;
  // student_id: number;
  blocked: boolean;
  semester = [{name:'First'}, {name:"Second"}]
  levels = [{name:'I'}, {name:"II"}]
  session = getSession()
  @Input() student_id: number
  constructor(private fb: FormBuilder, 
    private service: AppService, private pay_history : PaymentHistoryComponent) { }

  ngOnInit(): void {
    this.form = this.fb.group({    
      'semester': ['', Validators.required],
      'channel': ['', Validators.required],
      'amount': ['', Validators.required],
      'session': ['', Validators.required],
      'level': ['', Validators.required],
      
    })
  }

  
  makePayment() {
        this.service.showMessage(this.service.MSG_INFO, this.service.INFO_TITLE,this.service.PROCESS_SAVING )    
    this.loading = "p-button-raise p-col-4 p-p-3 p-mb-3 spinner"
    let payment = new addPayemnt()
    let form = this.form
    payment.amount = form.controls['amount'].value
    payment.channel = form.controls['channel'].value
    payment.semester = form.controls['semester'].value
    payment.session = form.controls['session'].value
    payment.level = form.controls['level'].value
    payment.student = +this.student_id    
    console.log(payment)
    this.service.studentService.addMakePayment(payment).subscribe(rs => {
      this.loading = "p-button-raise"
      this.blocked = false
      if (rs['message'] == this.service.SUCCESS) {
        this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['data'])
        this.pay_history.closeModal("Saved")
      } else {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['data'])
        this.pay_history.closeModal()
      }
    }, error => {
      this.loading = "p-button-raise"
      this.blocked = false
      this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
    })
  }
}
