import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/api/app.service';
import { BaseService } from 'src/app/api/Base.service';
import { getDate } from 'src/app/helper/date_format';

@Component({
  selector: 'app-personal-view',
  templateUrl: './personal-view.component.html',
  styleUrls: ['./personal-view.component.css']
})
export class PersonalViewComponent implements OnInit {

  email: string
  form_number: string

  imagePreview: string
  student_id: number = 0
  personalDetailsForm: FormGroup
  personalGuardianForm: FormGroup
  selectedStudent: any
  fullname: any;
  errOccured: boolean;
  pageLoading: boolean;
  
  @Input() is_refresh : boolean
  constructor(private service: AppService, private base: BaseService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.student_id = param.studentid
    })
  }

  ngOnInit(): void {
    this.get_student_details()   
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['is_refresh']) {      
      this.get_student_details()
    }
  }

  getDate(value) {
    return getDate(value, 'ddmmyy')
  }

  get_student_details() {
    this.pageLoading = true
    this.errOccured = false
    this.service.studentService.getStudentDetails(this.student_id)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.pageLoading = false
          this.selectedStudent = res['data']
        }
        else {
          this.pageLoading = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
        }
      }, err => {
        this.pageLoading = false
        this.errOccured = true
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }
}
