export function getSession() {
    let year = new Date().getFullYear();
    let nextYear = year + 1
    let upperYear = year + 2
    let prevYear = year - 1
    let yearBe4PrevYear = year - 2
    let yearMinus3 = year - 3
    let pYear = year -4
    let ppYear = year -5
    let sessions = [{ name: ppYear.toString() },{ name: pYear.toString() },{ name: yearMinus3.toString() }, { name: yearBe4PrevYear.toString() }, { name: prevYear.toString() }, { name: year.toString() }, { name: nextYear.toString() }, { name: upperYear.toString() }]
    return sessions
}
export function getFirstDayMonth(date) {
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();

    month = month < 10 ? '0' + month : month;

    return year + "-" + month + "-01"
};


export function getEndDayMonth(date) {
    let month: any = date.getMonth() + 1;
    let year: any = date.getFullYear();

    month = month < 10 ? '0' + month : month;

    return year + "-" + month + "-30"
};

export function getDay(date) {
    let day: any = new Date(date).getDate();
    // day = day < 10 ? '0' + day : day;
    return day
}
export function getYearMonth(date) {
    let month: any = new Date(date).getMonth() + 1;
    let year: any = new Date(date).getFullYear();

    month = month < 10 ? '0' + month : month;
    return  year + '-' + month 

}

// export function getDateDayMonthYear(date) {
//     let month: any = new Date(date).getMonth() + 1;
//     let day: any = new Date(date).getDate() 
//     let year: any = new Date(date).getFullYear();

//     month = month < 10 ? '0' + month : month;
//     return  day + '-' + month + '-'  + year
// }

export function getDate(date, mode = "mmddyy") {
    let month: any = new Date(date).getMonth() + 1;

    let day: any = new Date(date).getDate();

    let year: any = new Date(date).getFullYear();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;
    if (mode ==" mmddyy") {
        return month + '-' + day + '-' + year
    }
    if(mode == "ddmmyy"){
        return day + '-' + month + '-' + year
    }
    return year + '-' + month + '-' + day
}

export function todayFullDate() {
    let month: any = new Date().getMonth() + 1;
    let day: any = new Date().getDate();
    let year: any = new Date().getFullYear();

    month = month < 10 ? '0' + month : month;
    day = day < 10 ? '0' + day : day;

    return year + '-' + month + '-' + day
}

export function getCurrentDate() {
    let day: any = new Date().getDate();
    day = day < 10 ? '0' + day : day;
    return day
}

export function getCurrentMonth() {
    let month: any = new Date().getMonth() + 1;
    return month
}

export function getCurrentYear() {
    let year: any = new Date().getFullYear();

    return year
}

export function getCurrentTime() {
    let date = new Date()
    let hour = date.getHours();
    let minute = date.getMinutes();
    let a = 'AM'

    if (hour > 12) {
        a = 'PM'
    }
    else {
        a = 'AM'
    }

    return hour + ':' + minute + '' + a;
}

export function getFormatTime(val) {
    let date = new Date(val)
    let hour = date.getHours();
    let minute = date.getMinutes();
    let a = 'AM'

    if (hour > 12) {
        a = 'PM'
    }
    else {
        a = 'AM'
    }

    return hour + ':' + minute + '' + a;
}