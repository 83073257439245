import {Component} from '@angular/core';
import {trigger, state, transition, style, animate} from '@angular/animations';
import { AppService } from './api/app.service';
import { Router } from '@angular/router';
import { Constants } from './helper/constants';

@Component({
    selector: 'app-inline-profile',
    template: `
        <div class="profile" [ngClass]="{'profile-expanded':active}">
            <a href="#" (click)="onClick($event)">
                <img class="profile-image" src="assets/layout/images/default.png" />
                <span class="profile-name">{{fullname}}</span>
                <i class="pi pi-fw pi-chevron-down"></i>
                <span class="profile-role">{{role}}</span>
            </a>
        </div>

        <ul id="profile-menu" class="layout-menu" [@menu]="active ? 'visible' : 'hidden'">
            <li role="menuitem">
                <a routerLink="/home/profile" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-user"></i>
                    <span>Profile</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text" routerLink="/home/profile">Profile</div>
                </div>
            </li>
            
            <li role="menuitem">
                <a routerLink="/home/settings" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-cog"></i>
                    <span>Settings</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text" routerLink="/home/settings">Settings</div>
                </div>
            </li>
            <li role="menuitem">
                <a (click)="logout()" [attr.tabindex]="!active ? '-1' : null">
                    <i class="pi pi-sign-out"></i>
                    <span>Logout</span>
                </a>
                <div class="layout-menu-tooltip">
                    <div class="layout-menu-tooltip-arrow"></div>
                    <div class="layout-menu-tooltip-text" (click)="logout()">Logout</div>
                </div>
            </li>
        </ul>
    `,
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppProfileComponent {

    active: boolean;
    fullname : string
    role : string
    constructor(private service : AppService, private route: Router) {
        this.fullname = this.service.helperService.getUserName()
        this.role = this.service.helperService.getUserRole()
    }
    

    onClick(event) {
        this.active = !this.active;
        event.preventDefault();
    }

    logout(){
        this.service.helperService.removeFromStore(Constants.LOGIN_USER)
        this.route.navigateByUrl("/")
    }
}
