import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private http: HttpClient ,private base: BaseService) { }

  private baseUrl = this.base._baseUrl 

  getDashboard(session : string): Observable<any>{
    return this.http.get<any>(this.baseUrl +
      `/dashboard/data?session=${session}`)
      .pipe(
      catchError(err => this.base.errorHandler(err))
    )
  }
}
