import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BaseService } from './Base.service';

@Injectable({
  providedIn: 'root'
})
export class StudentRegService {
  constructor(private http: HttpClient,private base: BaseService) { }
  
  private baseUrl = this.base._baseUrl + '/student-reg'

  
  getEnterpreneur(): Observable<any> {  
    return this.http.get<any>(this.baseUrl +
      `/get-enterpreneuship`)
      .pipe(
        catchError(err => this.base.errorHandler(err))
      )
  }

}
