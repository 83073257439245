import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { approveReject } from 'src/app/model/staff';
import { jambsubjectsList } from 'src/app/model/student';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-approvalpage',
  templateUrl: './approvalpage.component.html',
  styleUrls: ['./approvalpage.component.css']
})
export class ApprovalpageComponent implements OnInit {
  pageloading : boolean = false
  errOccured : boolean = false
  studentId: number = 0
  staffId: number

  approveRejectForm: FormGroup
  approveReject = new approveReject()
  personalDetails: any
  // applyFor: any
  jambsubjectslist = new jambsubjectsList()
  jambsubjectsLists: jambsubjectsList[] = []
  jambRequestList: any
  totalScore: number = 0
  hndRequestList: any
  loadingAcptRej: boolean = false
  proposedcourse: any
  approveReason: any
  faculty: any
  programme:any
  courseGiven: any
  approveStatus: any = "Approved"
  changeProposeCourse: boolean

  approvedProposeCourse: any = "Approved"
  showCourses: boolean
  errOccur: any = false
  err: string
  approvedStatus : string
  isApproved : boolean = false
  isRejected: boolean = false
  studentAdmittedStatus: any;
  courseloading: boolean;
  courses: any[];
  programmes: any[]
  prog:any

  constructor(private service: AppService, private router: ActivatedRoute, private fb: FormBuilder) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })
    this.staffId = +this.service.helperService.getUserId()

  }
  ngOnInit(): void {
    // this.courses = [{ name: "Computer Science" }, { name: "Food Tech" }, { name: "ILS" }, { name: "SLT" }]
    // this.approveRejectForm = this.fb.group({
    // })
    this.getStudentDetails()
    this.isApproveStatus()
    this.checkAdmissiomStatus();
    this.getAllCourseAvailable()
    this.programmes = [{ name: "ND" }, { name: "HND" }]
  }

  getAllCourseAvailable() {
    this.courses = []
    this.service.adminService.getAvailableCourse()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.courses = res['data']
          this.courseloading = false
        }
        else {
          this.courseloading = false
          console.log(res['message'])
        }
      }, err => {
        this.courseloading = false  
      })
  }

  isApproveStatus() {
    if (this.approveStatus == "Approved" || this.approveStatus == true) {
      this.changeProposeCourse = true
    }
    else {
      this.changeProposeCourse = false
      this.approvedProposeCourse = "Approved"
    }
    this.isShowCourse()
  }

  isShowCourse() {
    if (this.approvedProposeCourse == "Approved" || this.approvedProposeCourse == true) {
      this.showCourses = false
    }
    else {
      this.showCourses = true
    }
  }
  json(data){
    return JSON.parse(data)
  }
  checkAdmissiomStatus() {
    // this.pageloading = true
    this.service.studentService.checkStudentAdmissiomStatus(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['data'])
          this.studentAdmittedStatus = res['data']
          // console.log(this.studentAdmittedStatus)
          if(this.studentAdmittedStatus.status == "Approved" || this.studentAdmittedStatus.status == "Rejected"){
            this.isApproved = true
          }
          
        }
        else {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['data'])
          console.log(res['data'])
          // this.isApproved = false
        }
      }, err => {
        // this.isApproved = false
        console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
      })
  }


  acceptRejectStudent() {
    this.loadingAcptRej = true
    let course = ''

    if (this.approvedProposeCourse == "Approved") {
      course = this.personalDetails?.proposedcourse
    }
    else {
      course = this.courseGiven
      // checkCourse = false
    }
    this.approveReject.approveddept = course
    this.approveReject.comment = this.approveReason
    this.approveReject.status = this.approveStatus
    this.approveReject.programme = this.prog
    this.approveReject.faculty = this.faculty
    this.approveReject.studentid = +this.studentId
    this.approveReject.approval = +this.staffId

    // console.log(this.approveReject)
    this.service.adminService.approveRejectStudent(this.approveReject)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['message'])
          console.log("saved")
          this.loadingAcptRej = false
          this.checkAdmissiomStatus()
        } else {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['data'])
          console.log("not saved")
          this.loadingAcptRej = false
          this.errOccur = true
          this.err = rs['data']
        }
      }, error => {
        this.loadingAcptRej = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, error)
        console.log(error)
      })
  }

  getStudentDetails() {
    this.pageloading = true
    this.errOccured = false
    this.service.studentService.getStudentDetails(this.studentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          // console.log(res)
          this.pageloading = false
          this.personalDetails = res['data']

          this.jambRequestList = res['data'].jambRequestList[0]
          this.jambsubjectsLists = this.jambRequestList?.subjectsList
          if (this.jambsubjectsLists) {
            this.jambsubjectsLists.forEach(en => {
              this.totalScore += en.scores
            })
          }
          this.hndRequestList = res['data'].hndRequestList[0]
        } else {
          this.pageloading = false
          // console.log(res['data'])
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
        }
      }, err => {
        this.pageloading = false
        this.errOccured = true
        console.log(err, "Err")
        this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, err)
      })
  }


}
