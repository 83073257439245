import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.css']
})
export class OverviewComponent implements OnInit {
  studentId: any;
  isCompleted: boolean;
  end : string = "END"
  pageloading: boolean;
  errOccured: boolean;
  staffId: number;
  
  loadingAcptRej: boolean;
  err: any;
  approveReason: string;
  courseGiven: string;
  personalDetails: any;
  courses: any[];
  courseloading: boolean;

  constructor(private service: AppService, private router: ActivatedRoute ) {
    this.router.params.subscribe(param => {
      this.studentId = param.studentid
    })
    this.staffId = +this.service.helperService.getUserId()
  }

  ngOnInit(): void {
    // this.getStudentDetails()    
  }

  
 
  reg_stage : number =0
  getRegStage() {
    let StudentId = this.service.helperService.getUserId();
    this.service.studentService.getRegStage(StudentId)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          if (res['data'] == 10) {
            this.isCompleted = true
            this.reg_stage =10
          }
          else
            this.isCompleted = false
            this.reg_stage = res['data']
        }
        else {
          this.isCompleted = false
        }
      }, err => {
        this.isCompleted = false
      })
  }

  // getStudentDetails() {
  //   this.pageloading = true
  //   this.errOccured = false
  //   this.service.studentService.getStudentDetails(this.studentId)
  //     .subscribe(res => {
  //       if (res['message'] == this.service.SUCCESS) {
  //         // console.log(res)
  //         this.pageloading = false
  //         this.personalDetails = res['data']
         
  //       } else {
  //         this.pageloading = false
  //         // console.log(res['data'])
  //         this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
  //       }
  //     }, err => {
  //       this.pageloading = false
  //       this.errOccured = true
  //       console.log(err, "Err")
  //       this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, err)
  //     })
  // }

}
