import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/api/app.service';
import { getDate } from 'src/app/helper/date_format';
import { courseList, addExamObtDataList } from 'src/app/model/student';

@Component({
  selector: 'app-olevel-view',
  templateUrl: './olevel-view.component.html',
  styleUrls: ['./olevel-view.component.css']
})
export class OlevelViewComponent implements OnInit {

  student_id: number
  pageLoading: boolean = false
  errOccured: boolean = false
  examinationRequestList: any

  index: number = 1
  courselists: courseList[] = []
  courselists2: courseList[] = []
  examObtDataList: addExamObtDataList[] = []
  examObtDataListPayload = new addExamObtDataList()
  examObtDataListPayload2 = new addExamObtDataList()
  seat: any;
  @Input() is_refresh : boolean
  constructor(private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.student_id = param.studentid
    })
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['is_refresh']) {
      this.getStudentDetails()
    }
  }

  ngOnInit(): void {
    this.getStudentDetails()
  }


  getDate(value) {
    return getDate(value)
  }

  getStudentDetails() {
    this.errOccured = false
    this.pageLoading = true

    this.service.studentService.getStudentDetails(this.student_id)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.seat =this.examObtDataList.length
          this.examObtDataList = res['data'].examinationRequestList          
          if (this.examObtDataList.length > 0) {
            // ********** FOR FIRST EXAM *************
            this.examObtDataListPayload = this.examObtDataList[0]
            this.courselists = this.examObtDataListPayload?.courseList            

            if (this.examObtDataList?.length > 1) {
              // ********** FOR SECOND EXAM *************
              this.examObtDataListPayload2 = this.examObtDataList[1]
              this.courselists2 = this.examObtDataListPayload2?.courseList
            }
          }
          this.pageLoading = false; 

        } else {
          this.pageLoading = false;          
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
        }
      }, err => {
        this.pageLoading = false;
        this.errOccured = true
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }
}
