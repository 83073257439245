import { Component, OnInit, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { AppService } from 'src/app/api/app.service';
import { getSession } from 'src/app/helper/date_format';
import { downloadAnyFile } from 'src/app/helper/downloader';

@Component({
  selector: 'app-entrepreneurship',
  templateUrl: './entrepreneurship.component.html',
  styleUrls: ['./entrepreneurship.component.css']
})
export class EntrepreneurshipComponent implements OnInit {

  pageloading: boolean = false
  errOccured: boolean = false
  students: [];
  selectedStudent = []
  statuses: any[];
  rowGroupMetadata: any;
  activityValues: number[] = [0, 100];
  @ViewChild('dt') table: Table;
  enterprenuers = [];
  enterprenuerloading: boolean;

   semesters = [{ name: 'First' }, { name: 'Second' }]
  // programmes = [{ name: 'ND' }, { name: 'HND' }]
  levels = [{ name: 'I' }, { name: 'II' }]
  // sessions = getSession()

  level: string;
  // session: string;
  // programme : string
  enterprenuer: string;
  semester:string
  loadingUpload: boolean;
  sessions = getSession()
  session : string

  constructor(private service: AppService,) { }
  ngOnInit(): void {
    // this.getAllStudents()
    this.session = this.sessions[3].name
    this.getAvailableEnterprenuer();
    this.semester = this.semesters[1].name
  }

  getAvailableEnterprenuer() {
    this.enterprenuerloading = true
    // this.errenterprenuer = false
    this.enterprenuers = []
    this.pageloading = true
    this.errOccured = false
    this.service.studentRegService.getEnterpreneur()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          let enterprenuers = res['data']
          this.enterprenuerloading = false
          enterprenuers.forEach(el => {
            this.enterprenuers.push({ name: el })
          })
          this.level = this.levels[0].name
          this.enterprenuer = this.enterprenuers[0].name
          this.getEnterpreneushipByCourseLevel()
        }
        else {
          this.enterprenuerloading = false
          this.pageloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          // console.log(res['message'])
        }
      }, err => {
        this.enterprenuerloading = false
        this.errOccured = true
        // this.errenterprenuer = true
        this.pageloading = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }
  getEnterpreneushipByCourseLevel() {
    if (this.enterprenuer != null && this.level != null) {
      this.pageloading = true
      this.errOccured = false
      this.students = []
      this.service.adminService.getEnterpreneushipByCourseLevel(this.enterprenuer, this.level,this.semester,this.session)
        .subscribe(res => {
          if (res['message'] == this.service.SUCCESS) {
            // this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
            this.students = res['data']
            this.pageloading = false
          }
          else {
            this.pageloading = false
            this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
            console.log(res['data'])
          }
        }, err => {
          this.pageloading = false
          this.errOccured = true
          console.log(err)
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
        })
    }
    else {
      this.students = []
      this.service.showMessage(this.service.MSG_INFO, this.service.ERROR_TITLE, "No Record Found")
    }
  }
  downloadStudent() {
    if (this.enterprenuer != null && this.level != null) {
      this.loadingUpload = true
      this.service.showMessage(this.service.MSG_INFO, this.service.INFO_TITLE, this.service.PROCESS_DOWNLOAD)

      this.service.fileService.downloadEnterprenuerStudent(this.session,this.enterprenuer, this.level,this.semester)
        .subscribe(rs => {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, " ")
          downloadAnyFile(rs, 'pdf')
        }, error => {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        })
        this.loadingUpload = false
    }
    else {
      this.students = [];this.loadingUpload = false
      this.service.showMessage(this.service.MSG_INFO, this.service.ERROR_TITLE, "No Record Found")
    }
  }
  // getStudentPaymentHistory(studentid: number) {
  //   this.route.navigateByUrl("/home/payment-history/" + studentid)
  // }
}
