import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { getAvailableDepartment } from 'src/app/model/student';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-departmentform',
  templateUrl: './departmentform.component.html',
  styleUrls: ['./departmentform.component.css']
})
export class DepartmentformComponent implements OnInit {

  countries: any[];
  personalDetailsForm: FormGroup
  departmentForm: FormGroup
  // departmentform: getAvailableDepartment;

  displayBasic: boolean | undefined;
  loading: boolean = false
  departmentID: number = 0
  errDepartment: boolean;
  departmentloading: boolean;
  departments: any[];
  public show: boolean = false;
  loadingRemove: boolean;
  constructor(private service: AppService, private fb: FormBuilder, private _router: Router) { }

  ngOnInit(): void {
    // this.productService.getProductsSmall().then(data => this.products = data);
    this.departmentForm = this.fb.group({
      'department': ['', Validators.required],
      'requirement': ['', Validators.required],
      'description': ['', Validators.required],
    })
    this.getAvailableDepartment()
  }

  toggled() {
    this.show = !this.show;
  }

  deleteDepartmentForm(id) {
    this.service.adminService.deleteDepartment(id).subscribe(
      (res) => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['data'])
          this.loadingRemove = false
          this.getAvailableDepartment()
        }
        else {
          this.loadingRemove = false
          this.service.showMessage(this.service.MSG_ERROR, this.service.FAILED, res['message'])
          console.log(res['data'])
        }
      }, err => {
        this.loadingRemove = false
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
        console.log(err)
      })
  }


  editDepartmentForm(departments) {
    let form = this.departmentForm
    form.get('department').setValue(departments.dept)
    form.get('requirement').setValue(departments.requirement)
    form.get('description').setValue(departments.description)
    this.departmentID = departments.id
    this.showBasicDialog()
  }


  showBasicDialog() {
    this.displayBasic = true;

  }
  iBasicDialog() {
    this.displayBasic = false;
  }


  addDepartmentForm() {
    let getDepartment = new getAvailableDepartment()
    this.loading = true

    let form = this.departmentForm
    getDepartment.dept = form.get('department').value
    getDepartment.description = form.get('description').value
    getDepartment.requirement = form.get('requirement').value
    getDepartment.id = this.departmentID  
    
    this.service.adminService.addDepartment(getDepartment)
      .subscribe(rs => {
        if (rs['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['data'])
          console.log("saved")
          this.loading = false
          this.getAvailableDepartment()
          this.iBasicDialog()
        } else {
          this.loading = false
          this.iBasicDialog()
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['data'])
          // console.log("not saved", rs['data'])

        }
      }, error => {
        this.loading = false
        this.iBasicDialog()
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, error)
        // this.blocked = false
        console.log(error)
      })
  }

  getAvailableDepartment() {
    this.departmentloading = true
    this.errDepartment = false
    // this.departments = [] 
    this.service.adminService.getAvailableDepartment()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.departments = res['data']
          // console.log(res)
          this.departmentloading = false
        }
        else {
          this.departmentloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          // console.log(res['message'])
        }
      }, err => {
        this.departmentloading = false
        this.errDepartment = true
        // console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }


}
