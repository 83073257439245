import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { getDate } from 'src/app/helper/date_format';
import { addSession } from 'src/app/model/student';
import { AppService } from 'src/app/api/app.service';

@Component({
  selector: 'app-semester',
  templateUrl: './semester.component.html',
  styleUrls: ['./semester.component.css']
})
export class SemesterComponent implements OnInit {

  pageLoading: boolean = false
  errOccured: boolean = false
  newDate = new Date();
  startDate = new Date

  // user: any[];
  first = 0;
  rows = 10;
  display: boolean = false;
  // courseloading: boolean;
  // departmentloading: boolean;
  // errDepartment: boolean;
  // departments: any[];
  semester: any;

  form: FormGroup = new FormGroup({})
  // errorMessage: string = "";
  blocked: boolean = false;
  loading: string = "p-button-raise p-col-4 p-p-3 p-mb-3";
  // courses: any[]
  // date10: Date;

  sessionId: number = 0
  years = []
  // today = ;
  year = new Date().getFullYear();
  nextYear = this.year + 1
  prevYear = this.year - 1
  sessions = [{ name: this.prevYear.toString() + "/" + this.year.toString() + ' Session' }, { name: this.year.toString() + "/" + this.nextYear.toString() + ' Session' },]
  stateOptions = [{ label: 'Open', value: 'Open' }, { label: 'Close', value: 'Close' }];
  session = new addSession();
  createdby: number = +this.service.helperService.getUserId();
  status = [{ name: "Active" }, { name: "Inactive" }]
  registrationStatus = [{ name: "in progress" }, { name: "closed" }]
  semesters = [{ name: "First" }, { name: "Second" }]
  constructor(private fb: FormBuilder, private messageService: MessageService,
    private service: AppService, private _route: Router) { }

  ngOnInit(): void {
    this.form = this.fb.group({
      'startDate': [new Date, Validators.required],
      'endDate': [new Date, Validators.required],
      'admissionyear': ['', Validators.required],
      'session': ['', Validators.required],
      'status': ['', Validators.required],
      'semester': ['', Validators.required],
      'registrationStatus': ['', Validators.required],
    })

    for (let i = -1; i < 2; i++) {
      this.years.push({ name: (this.year + i).toString() })
    }
    this.listAllSession()
  }

  showDialog() {
    this.form.reset()
    this.display = true;
  }
  hideDialog() {
    this.display = false;
  }

  valueChanged() {
    this.startDate = this.form.get('startDate').value
  }

  getDate(date) {
    return getDate(date)
  }

  listAllSession() {
    this.pageLoading = true
    this.errOccured = false
    this.service.adminService.getSession()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          this.semester = res['data']
          console.log(res['data'])
          this.pageLoading = false
        }
        else {
          this.pageLoading = false
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['data'])
        }
      }, err => {
        this.pageLoading = false
        this.errOccured = true
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MSG_ERROR)
      })
  }

  editsemester(semester) {
    this.showDialog()
    this.session = semester
    this.form.get('admissionyear').setValue(this.session.admissionyear)
    this.createdby = this.session.createdby
    this.sessionId = this.session.id
    this.form.get('session').setValue(this.session.session)
    this.form.get('semester').setValue(this.session.semester)
    this.form.get('status').setValue(this.session.status);
    this.form.get('registrationStatus').setValue(this.session.registration);
    let start = new Date(getDate(this.session.startdate))
    let end = new Date(getDate(this.session.enddate))
    
    this.form.get('startDate').setValue(start)
    this.form.get('endDate').setValue(end)
    // this.form.get('startDate').setValue(getDate(this.session.startdate))
    // this.form.get('endDate').setValue(getDate(this.session.enddate))

  }

  addUpdateSession() {
    this.blocked = true
    this.loading = "p-button-raise p-col-4 p-p-3 p-mb-3 spinner"
    let session = new addSession()
    session.admissionyear = this.form.get('admissionyear').value;
    session.createdby = this.createdby
    session.enddate = this.form.get('endDate').value;
    session.id = +this.sessionId
    session.session = this.form.get('session').value;    
    session.startdate = this.form.get('startDate').value
    session.semester = this.form.get('semester').value
    session.status = this.form.get('status').value;
    session.registration = this.form.get('registrationStatus').value
    
    this.service.adminService.addUpdateSession(session)
    .subscribe(rs => {
      this.loading = "p-button-raise"
      this.blocked = false
      if (rs['message'] == this.service.SUCCESS) {
        this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['data'])
        this.hideDialog()
        this.listAllSession()
      } else {
        this.hideDialog()
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['data'])
      }
    }, error => {
      this.hideDialog()
      this.loading = "p-button-raise"
      this.blocked = false
      this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MSG_ERROR)
    })
  }


  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.semester ? this.first === (this.semester.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.semester ? this.first === 0 : true;
  }


  goback() {
    this._route.navigateByUrl("/home/settings")
  }
}
