import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AppService } from 'src/app/api/app.service';
import { getDate } from 'src/app/helper/date_format';
import { jambsubjectsList } from 'src/app/model/student';

@Component({
  selector: 'app-jamb-view',
  templateUrl: './jamb-view.component.html',
  styleUrls: ['./jamb-view.component.css']
})
export class JambViewComponent implements OnInit {

 
  student_id: number
  pageLoading: boolean = false
  errOccured: boolean = false
  is_jambite: boolean =true
  jambRequestList: any
  index: number = 1
  totalScore: number = 0

  jambsubjectslist = new jambsubjectsList()
  jambsubjectsLists: jambsubjectsList[] = []

  hndRequestList: any
  @Input() is_refresh : boolean
  constructor(private service: AppService, private router: ActivatedRoute) {
    this.router.params.subscribe(param => {
      this.student_id = param.studentid
    })
  }

  ngOnInit(): void {
    this.getStudentDetails()
  }
  
  ngOnChanges(changes: SimpleChanges) {
    if (changes['is_refresh']) {
      this.getStudentDetails()
    }
  }

  getDate(value) {
    return getDate(value)
  }

  respondJamb: any
  respondHND: any
  getStudentDetails() {
    this.errOccured = false
    this.pageLoading = true

    this.service.studentService.getStudentDetails(this.student_id)
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {

          this.respondJamb = res['data'].jambRequestList[0]
          this.respondHND = res['data'].hndRequestList[0]
          this.jambRequestList = res['data'].jambRequestList[0]
          this.jambsubjectsLists = this.jambRequestList?.subjectsList
          this.totalScore = 0          
          if (this.jambsubjectsLists) {
            this.jambsubjectsLists.forEach(en => {
              this.totalScore += en.scores
            })
          }

          this.hndRequestList = res['data'].hndRequestList[0]
          this.pageLoading = false;
          if(this.respondHND == null && this.respondJamb == null){
            this.is_jambite = false
          }

        } else {
          this.pageLoading = false;
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
        }
      }, err => {
        this.pageLoading = false;
        this.errOccured = true
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }
}
