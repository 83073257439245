import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';
import { AppService } from 'src/app/api/app.service';
import { getSession } from 'src/app/helper/date_format';
import { downloadAnyFile } from 'src/app/helper/downloader';

@Component({
  selector: 'app-paid-student',
  templateUrl: './paid-student.component.html',
  styleUrls: ['./paid-student.component.css']
})
export class PaidStudentComponent implements OnInit {

  pageloading: boolean = false
  errOccured: boolean = false
  students: [];
  selectedStudent = []
  statuses: any[];
  rowGroupMetadata: any;
  activityValues: number[] = [0, 100];
  @ViewChild('dt') table: Table;
  // departments = [];
  // departmentloading: boolean;

  semesters = [{ name: 'First' }, { name: 'Second' }]
  levels = [{ name: 'I' }, { name: 'II' }]
  sessions = getSession()

  level: string;
  session: string;
  semester: string;

  constructor(private service: AppService, private route: Router) { }
  ngOnInit(): void {
    // this.getAllStudents()
    this.semester = this.semesters[0].name
    this.session = this.sessions[1].name
    this.level = this.levels[0].name
    this.getPaidStudentByLevelSemesterSession()

  }

  getPaidStudentByLevelSemesterSession() {
    if (this.level != null && this.semester  != null  && this.session != null) {
      this.pageloading = true
      this.errOccured = false
      this.students = []
      
      this.service.studentService.getPaidStudentByLevelSemesterSession(this.level, this.semester, this.session)
        .subscribe(res => {
          if (res['message'] == this.service.SUCCESS) {
            this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
            this.students = res['data']
            this.pageloading = false
      // console.log(res['data'])
          }
          else {
            this.pageloading = false
            this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
            console.log(res['data'])
          }
        }, err => {
          this.pageloading = false
          this.errOccured = true
          console.log(err)
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, err)
        })
    }
    else{
      this.students =[]     
      this.service.showMessage(this.service.MSG_INFO, this.service.ERROR_TITLE, "No Record Found") 
    }
  }

  getStudentPaymentHistory(studentid: number) {
    this.route.navigateByUrl("/home/payment-history/" + studentid)
  }

  downloadExaminationClearance(row){    
      this.service.showMessage(this.service.MSG_INFO, this.service.INFO_TITLE, this.service.PROCESS_DOWNLOAD)      
      this.service.fileService.downloadStudentsExaminationClearance(row?.level, row?.semester, row?.session, row.student.id)
        .subscribe(rs => {
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, "Clearance Form Downloaded ")
          downloadAnyFile(rs, 'pdf')
        }, error => {
          this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
        })    
  }
}
