import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { AppService } from 'src/app/api/app.service';
import { Staff } from 'src/app/model/staff';

@Component({
  selector: 'app-all-admin',
  templateUrl: './all-admin.component.html',
  styleUrls: ['./all-admin.component.css']
})
export class AllAdminComponent implements OnInit {

  
  pageLoading: boolean = false
  errOccured: boolean = false
  // customers: Customer[];
  user: any[];
  first = 0;
  // items: MenuItem[];
  rows = 10;
  display: boolean = false;
  courseloading: boolean;
  departmentloading: boolean;
  errDepartment: boolean;
  departments: any[];
  // cities: City[];
  // selectedCity: City;

  constructor(private fb: FormBuilder, private messageService: MessageService,
    private service: AppService, private route: Router) { }

  form: FormGroup = new FormGroup({})
  errorMessage: string = "";
  blocked: boolean = false;
  loading: string = "p-button-raise p-col-4 p-p-3 p-mb-3";

  // selectedState: any = null;
  // selectedCorse: any = null;

  courses: any[] 

  ngOnInit(): void {
    this.form = this.fb.group({
      'firstname': [],
      'lastname': ['', Validators.required],
      'email': ['', Validators.required],
      'phone': ['', Validators.required],
      'password': ['', Validators.required],
      'department': [''],
      'course': [''],
      'address': [''],
    })

    this.getUsers()
    this.getAllCourse()
    this.getDepartment()
  }

  showDialog() {
    this.display = true;
  }
  
  hideDialog(){
    this.display = false
  }

  getUsers() {
    this.pageLoading = true
    this.errOccured = false
    this.service.adminService.listStaff()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.pageLoading = false;
          this.user = res['data']
          // this.customers = res['data']

        } else {
          this.pageLoading = false;
          console.log(res['data'])
          this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, res['message'])
        }
      }, err => {
        this.pageLoading = false;
        this.errOccured = true
        console.log(err, "Err")
        this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, err)
      })
  }

  getAllCourse() {
    this.courseloading = true
    this.errOccured = false
    this.courses = []
    this.service.adminService.getAllCourse()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.courses = res['data']
          // console.log(res)
          this.courseloading = false
        }
        else {
          this.courseloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['message'])
        }
      }, err => {
        this.courseloading = false
        this.errOccured = true
        console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  getDepartment() {
    this.departmentloading = true
    this.errDepartment = false
    this.departments = []
    this.service.adminService.getAvailableDepartment()
      .subscribe(res => {
        if (res['message'] == this.service.SUCCESS) {
          this.departments = res['data']          
          this.departmentloading = false
        }
        else {
          this.departmentloading = false
          this.service.showMessage(this.service.MSG_WARNING, this.service.SUCCESS_TITLE, res['message'])
          console.log(res['message'])
        }
      }, err => {
        this.departmentloading = false
        this.errDepartment = true
        console.log(err)
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
      })
  }

  createStaff() {
    this.blocked = true
    this.loading = "p-button-raise p-col-4 p-p-3 p-mb-3 spinner"

    let staff = new Staff()
    staff.address = this.form.controls['address'].value;
    staff.dept = this.form.controls['department'].value
    staff.email = this.form.controls['email'].value;
    staff.fullname = this.form.controls['firstname'].value + ' ' + this.form.controls['lastname'].value;
    staff.phone = this.form.controls['phone'].value;
    staff.designation = this.form.controls['course'].value
    staff.password = this.form.controls['password'].value;
    
    this.service.adminService.addStaff(staff).subscribe(rs => {
      this.loading = "p-button-raise"
      this.blocked = false
      if (rs['message'] == this.service.SUCCESS) {
        this.service.showMessage(this.service.MSG_SUCCESS, this.service.SUCCESS_TITLE, rs['data'])
        this.hideDialog()
      } else {
        this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, rs['data'])
        this.hideDialog()
      }
    }, error => {
      this.loading = "p-button-raise"
      this.blocked = false
      this.service.showMessage(this.service.MSG_ERROR, this.service.ERROR_TITLE, this.service.MESSAGE_ERROR)
    })
  }


  next() {
    this.first = this.first + this.rows;
  }

  prev() {
    this.first = this.first - this.rows;
  }

  reset() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return this.user ? this.first === (this.user.length - this.rows) : true;
  }

  isFirstPage(): boolean {
    return this.user ? this.first === 0 : true;
  }


}
