import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { resetPassword } from 'src/app/model/entrance';

@Component({
  selector: 'app-changepassword',
  templateUrl: './changepassword.component.html',
  styleUrls: ['./changepassword.component.css']
})
export class ChangepasswordComponent implements OnInit {
  password:resetPassword
  passwordForm:FormGroup
    constructor() { }
  
    ngOnInit(): void {
      this.passwordForm=new FormGroup({
        oldpassword:new FormControl('', Validators.required),
        newpassword:new FormControl('', Validators.required),
        pin:new FormControl('', Validators.required),
      })
    }
    
  
  }
