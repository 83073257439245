export class addStudent {
    contactaddress: string
    courtavidavid: string
    dob: any
    email: string
    fullname: string
    healthchallenge: string
    healthreason: string
    id: number
    lga: string
    maritalstatus: string
    permanentaddress: string
    phone: string
    religion: string
    sign: string
    state: string
    proposedcourse: string
    formnumber: string

    files: file
    nationality: string
    gender : string
}
export class addOlevelSubject{
    grade: string = ''
    subject: string
  }
  
export class addExaminationObt {
    dataList: addExamObtDataList[]
    studentid: number
}
export class addExamObtDataList {
    courseList: courseList[]
    examsType: string
    id: number
}

export class courseList {
    grade: string
    subject: string
}

export class institutionAttd {
    dataList: institutionAttdDataList[]
    studentid: number
}
export class institutionAttdDataList {
    from: string
    id: number
    nameofschool: string
    qualificationObtained: string
    schoollocation: string
    to: string
}

export class ndResult {
    hndRequestList: hndRequestList[]
    studentid: number
}

export class hndRequestList {
    grade: string
    id: number
    ndresult: string
    yrExamination: string
}

export class parentGuardian {
    dataList: parentGuardianDataList[]
    student: number
}
export class parentGuardianDataList {
    contactAddress: string
    emailAddress: string
    fathermobile: string
    fullname: string
    id: number
    mothermobile: string
    relationship: string
}

export class addSchoolFee {
    dataList: addSchoolFeeDataList[]
    studentid: number
}
export class addSchoolFeeDataList {
    contactaddress: string
    email: string
    id: number
    moblie: string
    name: string
    relationship: string
    scholarship: string
    schorlashipreason: string
}

export class addJambDatils {
    jambRequestList: jambRequestList[]
    studentid: number
}

export class addSession {
    admissionyear: string
    createdby: number | 0
    enddate: any
    id: number | 0
    session: string
    startdate: any
    status: string
    semester : string
    registration: string
}

export class addCourses {
    coursecode: string
    coursetitle: string
    id: number = 0
    programme: string
    semester: string
    level: string
}

export class getAvailableDepartment {
    dept: string
    description: string
    id: number
    requirement: string
}

export class jambRequestList {
    id: number
    regNo: string
    subjectsList: jambsubjectsList[]
    yrExams: string
}

export class jambsubjectsList {
    scores: number
    subject: string
}

export class file {
    description: string
    filename: string
    id: number 
}


export class updateFormStage {
    id: number
    stage: any
}
    
export class departmentLis{
    dept: string
    description: string
    id: number
    requirement: any
  }

  export class addPayemnt{
    amount: number
    channel: string
    semester: string
    session: string
    level: string
    student: number
  }